import styled from 'styled-components';
import { BlankSpace, SpacerContainer } from '../genericComponents/layouts';
import {
  CardTitle, Text1, Text3, Text3M, Text5M,
} from '../genericComponents/texts';
import selloAutofact from '../assets/img/selloAutofact.svg';
// import reportImg from '../assets/img/report.svg';
import { priceFormat, roundPrice } from '../priceFormat';

interface Props {
  vehicle:{
    completeName:string,
    autofactPrice?: number;
    carouselImages: string[];
    patent?: string;
    mileage?: number;
    color?: string;
    year?: number;
    transmission?: string;
    engine?: string;
    sinister: boolean;
    technicalInspection: boolean;
    region: number;
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  setAutofactStampVisible: Function;
}

export default function ConsultVehiclePDF(props:Props) {
  const {
    setAutofactStampVisible,
  } = props;

  const Mensajes = {
    patente: 'No se ha encontrado patente.',
    autofactPrice: 'No se ha encontrado precio autofact.',
    transmission: 'No se ha encontrado transmision.',
    engine: 'No se ha encontrado motor.',
    color: 'No se ha encontrado color',
    mileage: 'No se ha encontrado kilometraje.',
    noApply: 'No aplica',
  };
  const { vehicle } = props;
  const mileageData = vehicle.mileage ? priceFormat(vehicle.mileage) : Mensajes.mileage;
  const colorData = vehicle.color ? vehicle.color : Mensajes.color;
  return (
    <Container>
      <DSKContainer1>
        <CardTitle>
          {vehicle.completeName}
        </CardTitle>
        <CarDetails>
          <CardDetailItem title="Patente" value={vehicle.patent && vehicle.patent !== 'null' ? vehicle.patent : Mensajes.noApply} />
          <CardDetailItem title="Kilometraje" value={mileageData} />
          <CardDetailItem title="Color" value={vehicle.patent ? colorData : Mensajes.noApply} />
          <CardDetailItem title="Año" value={`${vehicle.year}`} />
          <CardDetailItem title="Motor" value={vehicle.engine ? vehicle.engine : Mensajes.engine} />
          <CardDetailItem title="Transmisión" value={vehicle.transmission ? vehicle.transmission : Mensajes.transmission} />
        </CarDetails>
        <RecommendedPrice>
          <Text3>Precio recomendado por Autofact</Text3>
          <SpacerContainerPaddingLess>
            <SubCont1>
              <BlankSpace height="4px" />
              <Text5M>
                $
                {vehicle.autofactPrice ? priceFormat(roundPrice(vehicle.autofactPrice, 1)) : Mensajes.autofactPrice}
              </Text5M>
              <BlankSpace height="4px" />
              <SubCont2>
                <Text1>
                  Este es el precio que nosotros te recomendamos, esperado para vehículo en condiciones normales
                </Text1>
                <BlankSpace width="10px" />
              </SubCont2>
            </SubCont1>
            <SubCont3 onClick={() => setAutofactStampVisible(true)}>
              <img src={selloAutofact} width={25} height={25} alt="" />
              <Text3M>Sello Autofact</Text3M>
            </SubCont3>
          </SpacerContainerPaddingLess>
        </RecommendedPrice>
        <BlankSpace height="10px" />
      </DSKContainer1>
      <DSKcontainer2>
        {vehicle.carouselImages.slice(0, 1).map((image, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <img src={image} key={index} alt="" style={{ maxWidth: '80%', maxHeight: '80%' }} />
        ))}
      </DSKcontainer2>
    </Container>
  );
}

function CardDetailItem({ title, value }: { title: string, value: string }) {
  return (
    <CarDetailsCont>
      <Text3M>{title}</Text3M>
      <BlankSpace height="4px" />
      <Text1>{value}</Text1>
    </CarDetailsCont>
  );
}

// function CardDetailFactItem({ title, value }: { title: string, value: string }) {
//   return (
//     <CarDetailSubCont1>
//       <img src={reportImg} width={30} height={30} alt="" />
//       <BlankSpace height="6px" />
//       <Text3>{title}</Text3>
//       <BlankSpace height="4px" />
//       <Text3M>{value}</Text3M>
//     </CarDetailSubCont1>
//   );
// }

const Container = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
// const PaddingContainer = styled.div`
//   padding: 0px 10px;
// `;
const RecommendedPrice = styled.div`
  padding: 5px 30px;
  margin-top: 5px;
  border-radius: 10px;
  background: #EBF1F5;
`;
const SpacerContainerPaddingLess = styled(SpacerContainer)`padding:0;`;
const SubCont1 = styled.div`
  display: flex;
  flex-direction: column;
`;
const SubCont2 = styled.div`
  display: flex;
`;
const SubCont3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 120px;
  margin-left: 30px;
`;
// const SubCont4 = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;
const CarDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const CarDetailsCont = styled.div`
  width: 16%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
`;
// const CarDetailSubCont1 = styled.div`
//   width: 30%;
//   display: flex;
//   flex-direction: column;
// `;

const DSKContainer1 = styled.div`
  width: 60%;
  @media (max-width: 700px) {
    width: 100%;
  }
`;
const DSKcontainer2 = styled.div`
  width:40%;
  margin: 25px 0;
  display: flex;
  justify-content: center;
`;
