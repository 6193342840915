import styled from 'styled-components';
import ComparativeBar from '../genericComponents/ComparativeBar';
import { BlankSpace } from '../genericComponents/layouts';
import { CardTitle } from '../genericComponents/texts';
import RetomaPrice from '../genericComponents/RetomaPrice';
import CardQuestions from '../genericComponents/CardQuestions';
import ligthImg from '../assets/img/ligth.svg';
import calculatorImg from '../assets/img/calculator.svg';
import { priceFormat, roundPrice } from '../priceFormat';

interface Props{
  vehicle:{
    completeName:string,
    clientPrice?: number;
    avgPrice?: number;
    minPrice?: number;
    maxPrice?: number;
    rules?: {
      type: string,
      detail: string,
      discount_value?: number,
    }[];
    region: number;
    public: boolean;
  }
}

const Mensajes = {
  clientPrice: 'No se ha encontrado precio autofact.',
  avgPrice: 'No se ha encontrado precio promedio.',
  minPrice: 'No se ha encontrado precio mínimo.',
  maxPrice: 'No se ha encontrado precio máximo.',
  rules: 'No se ha encontrado',
};

export default function ExpectedResumePrice(props:Props) {
  const { vehicle } = props;
  const baseRule = vehicle.rules?.find((r) => r.type === 'base');
  const normalRule = vehicle.rules?.find((r) => r.type === 'normal');
  return (
    <Container>
      <SubCont2>
        <CardTitle>
          Rango de precio de retoma para
          {' '}
          {vehicle.completeName}
        </CardTitle>
        {(() => {
          if (!vehicle.public && vehicle.clientPrice) {
            return (
              <RetomaPrice
                text="Precio de retoma usuario"
                price={vehicle.clientPrice ? priceFormat(roundPrice(vehicle.clientPrice, 1)) : Mensajes.clientPrice}
                position="center"
              />
            );
          }
          return (
            <BlankSpace height="10px" />
          );
        })()}

        <ComparativeBar
          TriangleTop1={false}
          TriangleTop2={false}
          TriangleTop3={false}
          TriangleBottom1
          TriangleBottom2
          TriangleBottom3
        />

        <SubCont1>
          <CardPrices
            value={vehicle.minPrice ? priceFormat(roundPrice(vehicle.minPrice, 1)) : Mensajes.minPrice}
            text="Precio mínimo retoma"
            bold={false}
          />
          <CardPrices
            value={vehicle.avgPrice ? priceFormat(roundPrice(vehicle.avgPrice, 1)) : Mensajes.avgPrice}
            text="Precio promedio retoma"
            bold
          />
          <CardPrices
            value={vehicle.maxPrice ? priceFormat(roundPrice(vehicle.maxPrice, 1)) : Mensajes.maxPrice}
            text="Precio máximo retoma"
            bold={false}
          />
        </SubCont1>
        {(() => {
          if (!vehicle.public) {
            return (
              <>
                <PaddingCardTitle>
                  <CardTitle>
                    Reglas de retoma
                  </CardTitle>
                </PaddingCardTitle>
                <CarDetails>
                  <Table>
                    <tbody>
                      <tr>
                        <Tableth>Tipo</Tableth>
                        <Tableth>Detalle</Tableth>
                        <Tableth>Descuento</Tableth>
                      </tr>
                      <tr>
                        <Tabletd>Base</Tabletd>
                        <Tabletd>{baseRule?.detail ? 'Ajuste aplicado por reglas de retoma base' : '-'}</Tabletd>
                        <Tabletd>
                          $
                          {priceFormat(roundPrice(baseRule?.discount_value || 0, 1))}
                        </Tabletd>
                      </tr>
                      <tr>
                        <Tabletd>Normal</Tabletd>
                        <Tabletd>{normalRule?.detail ? 'Ajuste aplicado por reglas de retoma normal' : '-'}</Tabletd>
                        <Tabletd>
                          $
                          {priceFormat(roundPrice(normalRule?.discount_value || 0, 1))}
                        </Tabletd>
                      </tr>
                    </tbody>
                  </Table>
                </CarDetails>
              </>
            );
          }
          return (
            <SubCont1>
              <CardQuestions
                icon={ligthImg}
                question="¿Por qué es necesario conocer el precio de retoma?"
                response={`El precio de retoma es aquel que pagan las 
                automotoras o concesionarios cuando las personas los 
                entregan en parte de pago de su nuevo vehículo adquirido. 
                Es importante conocer el precio de retoma porque es un valor 
                técnico que sirve como indicador.`}
              />

              <CardQuestions
                icon={calculatorImg}
                question="¿Cómo calculamos el precio de retoma?"
                response={`Se calcula a través de la aplicación de 
                reglas de descuento que el usuario definió a partir 
                del valor de mercado Autofact del vehículo. Se obtiene 
                un precio de retoma sugerido para vehículos usados en 
                condiciones normales.
                `}
              />
            </SubCont1>
          );
        })()}

      </SubCont2>
      {(() => {
        if (vehicle.public) {
          return (
            <div />
          );
        }
        return (
          <SubCont3>
            <CardQuestions
              icon={ligthImg}
              question="¿Por qué es necesario conocer el precio de retoma?"
              response={`El precio de retoma es aquel que pagan las 
              automotoras o concesionarios cuando las personas los 
              entregan en parte de pago de su nuevo vehículo adquirido. 
              Es importante conocer el precio de retoma porque es un valor 
              técnico que sirve como indicador.`}
            />

            <CardQuestions
              icon={calculatorImg}
              question="¿Cómo calculamos el precio de retoma?"
              response={`Se calcula a través de la aplicación de 
              reglas de descuento que el usuario definió a partir 
              del valor de mercado Autofact del vehículo. Se obtiene 
              un precio de retoma sugerido para vehículos usados en 
              condiciones normales.
              `}
            />
          </SubCont3>
        );
      })()}
    </Container>
  );
}

function CardPrices({ value, text, bold }: { value: string, text: string, bold: boolean }) {
  return (
    <CarPriceCont>
      <Price fontWeight={bold ? 'bold' : '100'}>
        $
        {value}
      </Price>
      <TextPrice>{text}</TextPrice>
    </CarPriceCont>
  );
}

const Container = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;

  @media (max-width: 700px){
    flex-direction: column;
  }
`;
const SubCont1 = styled.div`
  display: flex;
  align-items: center;
`;
const SubCont2 = styled.div`
  flex:.75;
  padding: 0px 10px;
  @media (max-width: 700px){
    flex:1;
  }
`;
const SubCont3 = styled.div`
  flex:.25;
  border-left: 1px solid #EBF1F5;
  @media (max-width: 700px){
    flex:1;
  }
`;
const CarPriceCont = styled.div`
  width: 100%;
  margin: 5px auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Price = styled.label`
  font-size: 18px;
  color: #074bca;
  font-weight: ${({ fontWeight }: { fontWeight: string }) => fontWeight}; 
  margin: auto;
`;

const TextPrice = styled.label`
  max-width: 100px;
  font-size: 10px;
  color: #000;
  margin: auto;
  text-align: center;
`;

const CarDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #EBF1F5;
  @media (max-width: 700px){
    border-radius: 0;
    border:none;
    border-bottom: 1px solid #EBF1F5;
    border-top: 1px solid #EBF1F5;
  }
`;

const Table = styled.table`
  margin: 20px 0;
  border: none;
  width: 100%;
  @media (max-width: 700px){
    width: 100%;
  }
`;

const Tableth = styled.th`
  font-size: 14px;
  color: #3D5176;
  font-family: 'Roboto medium';
  text-align: left;
  padding: 10px 15px;
`;

const Tabletd = styled.td`
  font-size: 14px;
  color: #3D5176;
  text-align: left;
  padding: 10px 15px;
  @media (max-width: 700px){
    width: 10%;
    padding: 15px 0;
  }
`;
const PaddingCardTitle = styled.div`
  padding: 10px 0px;
`;
