import { useState } from 'react';
import styled from 'styled-components';
import { BlankSpace } from '../genericComponents/layouts';
import { Text4 } from '../genericComponents/texts';
import arrowDownImg from '../assets/img/upload.png';
import arrowUpImg from '../assets/img/down-arrow.png';

export default function CardInfoDrop({
  children, title, startsOpen = false, hiddenArrowPDF = false,
}: { title: string, children: React.ReactNode, startsOpen?: boolean, hiddenArrowPDF?: boolean }) {
  const [isOpen, setisOpen] = useState(startsOpen);
  const [hiddenArrow] = useState(hiddenArrowPDF);
  return (
    <InfoDesplegableContainer>
      <InfoDesplegableSubCont onClick={() => setisOpen(!isOpen)}>
        <SubCont2>
          <Text4>{title}</Text4>
        </SubCont2>
        <BlankSpace width="10px" />
        {(hiddenArrow)
          ? (
            <ButtonWrapper>
              <img width={12} height={12} src={isOpen ? arrowDownImg : arrowUpImg} alt="" />
            </ButtonWrapper>
          )
          : '' }
      </InfoDesplegableSubCont>
      <div className={!isOpen ? 'closed' : 'opened'}>
        {children}
      </div>
    </InfoDesplegableContainer>
  );
}

const InfoDesplegableContainer = styled.section`
  border-bottom: 1px solid #EBF1F5  ;
  padding: 5px;
  .opened{
    transition: max-height 0.5s ease-in;
    max-height: auto;
    padding: 5px 10px;
  }
  .closed{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.20s ease-out;
    padding: 0;
  }
`;
const InfoDesplegableSubCont = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;
const SubCont2 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const ButtonWrapper = styled.button`
  display: flex;
  background: none;
  border: none;
  outline: none;
`;
