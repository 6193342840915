import styled from 'styled-components';
import rightArrowModal from '../assets/img/rightArrowModal.png';
import { BlankSpace } from '../genericComponents/layouts';

interface Props{
  // eslint-disable-next-line @typescript-eslint/ban-types
  setVisible: Function,
  textButton: string,
  hideFooter?: boolean,
  children: React.ReactNode
}

export default function ModalMoreInfo({
  setVisible, textButton, children, hideFooter,
}: Props) {
  return (
    <>
      <HeaderModal>
        <span tabIndex={0} role="button" onKeyDown={() => setVisible(false)} onClick={() => setVisible(false)}>
          <img width={12} height={12} src={rightArrowModal} alt="" />
        </span>
        <BlankSpace width="15px" />
        <TextModal>{textButton}</TextModal>
      </HeaderModal>
      {children}
      {!hideFooter && (
        <FooterModal onClick={() => setVisible(false)}>
          <span>
            <TextModal>Volver</TextModal>
          </span>
        </FooterModal>
      )}
    </>
  );
}

const HeaderModal = styled.div`
  height: 60px;
  padding: 0 16px;
  background: #155892;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const TextModal = styled.label`
  color: #fff;
  display: flex;
  flex:1;
  font-size: 14px;
  font-family: 'Roboto medium';
`;
const FooterModal = styled.div`
  width: 95%;
  height: 50px;
  background: #155892;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 8px;
  border-radius: 10px;
`;
