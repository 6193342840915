import React from 'react';
import styled from 'styled-components';
import { BlankSpace, ContainerScroll } from '../genericComponents/layouts';
import {
  CardTitle, Text3, Text4, Text4M,
} from '../genericComponents/texts'; import feliz from '../assets/img/feliz.png';
import mediofeliz from '../assets/img/mediofeliz.png';
import nofeliz from '../assets/img/nofeliz.png';
import molesto from '../assets/img/molesto.png';

import CardWarning from '../genericComponents/CardWarning';
import { priceFormat } from '../priceFormat';
import ConditionPrices from '../models/conditionPrices.model';

interface Props{
  conditionPrices: ConditionPrices
}

export default function ConditionalPriceAdjustmen({ conditionPrices }:Props) {
  return (
    <Container>
      <SubCont1>
        <CardTitle>
          Según estado
        </CardTitle>
        <ContainerScroll>
          <CardItemComparative
            price={`${priceFormat(conditionPrices.excelent_value || 0)}`}
            text="Como un vehículo de tienda, el precio puede alcanzar a un auto del año"
            textIcon="Excelente"
            icon={feliz}
          />
          <CardItemComparative
            price={`${priceFormat(conditionPrices.good_value || 0)}`}
            text="Con todas las mantenciones, puedes obtener un buen precio"
            textIcon="Muy bueno"
            icon={mediofeliz}
          />
          <CardItemComparative
            price={`${priceFormat(conditionPrices.regular_value || 0)}`}
            text="Tiene desgastes propios del uso, tienen mantenciones mínimas"
            textIcon="Bueno"
            icon={nofeliz}
          />
          <CardItemComparative
            price={`${priceFormat(conditionPrices.bad_value || 0)}`}
            text="Tiene desgastes notorios, desgaste de pintura, abollones y le faltan mantenciones."
            textIcon="Regular"
            icon={molesto}
          />
        </ContainerScroll>
        <CardWarning
          text="Este tipo de precio según condición del vehículo se calculó en torno al precio de mercado Autofact"
        />

      </SubCont1>

      <SubCont2>
        <CardTitle>
          Considera estos tips
        </CardTitle>
        <CardTips
          title="Si está como nuevo"
          text="Como un vehículo de tienda, el precio puede alcanzar a un auto del año."
          icon={feliz}
        />
        <CardTips
          title="Muy buenas condiciones"
          text="Con todas las mantenciones, puedes obtener un buen precio."
          icon={mediofeliz}
        />
        <CardTips
          title="Promedio"
          text="Tiene desgastes propios del uso, tienen mantenciones mínimas."
          icon={nofeliz}
        />
        <CardTips
          title="Condiciones media baja"
          text="Con raspones y piezas por arreglar."
          icon={molesto}
        />
        <CardWarning
          text="Debes considerar que todas estas variables pueden afectar al precio final de compra o venta del vehículo"
        />
      </SubCont2>
    </Container>
  );
}

function CardItemComparative({
  price, text, textIcon, icon,
}: { price: string, text: string, textIcon: string, icon: string }) {
  return (
    <CardItemComparativeContainer>
      <ItemComparativeSubCont1>
        <ItemPrice>
          $
          {price}
        </ItemPrice>
        <TriangleBottom />
      </ItemComparativeSubCont1>

      <ItemComparativeSubCont2>
        <img src={icon} width={20} height={20} alt="" />
        <BlankSpace width="5px" />
        <Text4>{textIcon}</Text4>
      </ItemComparativeSubCont2>

      <ItemComparativeSubCont3>
        <Text3>{text}</Text3>
      </ItemComparativeSubCont3>
    </CardItemComparativeContainer>
  );
}

function CardTips({ title, text, icon }: { title: string, text: string, icon: string }) {
  return (
    <CardTipsConainer>
      <CardTipsSunCont1>
        <img src={icon} width={20} height={20} alt="" />
        <BlankSpace width="10px" />

        <CardTipsSunCont2>
          <Text4M>{title}</Text4M>
          <BlankSpace height="5px" />
          <Text4>{text}</Text4>
        </CardTipsSunCont2>
      </CardTipsSunCont1>
    </CardTipsConainer>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px){
    flex-direction: column;
  }
`;
const SubCont1 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  justify-items: flex-start;
  width: 70%;
  @media (max-width: 700px){
    width: unset;
    flex:1;
  }
`;
const SubCont2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 0px 10px;
  border: none;
  @media (max-width: 700px){
    border-top: 1px solid #EBF1F5;
    padding-top: 20px;
    width: unset;
    flex:1;
  }
`;
const CardItemComparativeContainer = styled.div`
  height: 200px;
  width: 120px;
  padding: 20px 10px;
  margin: 0px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ItemComparativeSubCont1 = styled.div`
  display: flex;
  flex-direction: column;
  height: 'auto';
  
`;
const ItemComparativeSubCont2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
  width: 120px;
  height: 20px;
`;

const ItemComparativeSubCont3 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const ItemPrice = styled.label`
  width: 120px;
  min-width: 100px;
  background-color: #155892;
  border-radius: 15px;
  color: #fff;
  text-align: center;
  padding: 5px;
`;
const TriangleBottom = styled.div`
  width: 0;  
  height: 0;
  margin: 0 auto;
  border-right: 10px solid transparent;
  border-top: 10px solid #155892; 
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
`;
const CardTipsConainer = styled.div`
  margin: 20px 0;
`;
const CardTipsSunCont1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const CardTipsSunCont2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
