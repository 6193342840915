import styled from 'styled-components';
import Taxes from '../models/taxes.model';

interface Props {
  engine?: string,
  transmission?:string,
  fuel?:string,
  mileage?:number,
  color?:string,
  patent?:string,
  taxes?:Taxes,
  regionName?:string,
}

export default function VehicleCharacteristics({
  engine, transmission, fuel, mileage, color, patent, taxes, regionName,
}: Props) {
  return (
    <Container>
      <Table>
        <tbody>
          <tr>
            <Tableth2>Caracteristicas</Tableth2>
            <Tableth2>Item - Servicio</Tableth2>
          </tr>
          <tr>
            <Tabletd2>Kilometraje</Tabletd2>
            <Tabletd2>{!mileage ? ' - ' : mileage}</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>Color</Tabletd2>
            <Tabletd2>{!color ? ' - ' : color}</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>Motor</Tabletd2>
            <Tabletd2>{!engine ? ' - ' : engine}</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>Combustible</Tabletd2>
            <Tabletd2>{!fuel ? ' - ' : fuel}</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>Transmisión</Tabletd2>
            <Tabletd2>{!transmission ? ' - ' : transmission}</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>Tiene IVA</Tabletd2>
            <Tabletd2>{taxes ? 'Si' : 'No'}</Tabletd2>
          </tr>
        </tbody>
      </Table>
      <Table>
        <tbody>
          <tr>
            <Tableth2>Caracteristicas</Tableth2>
            <Tableth2>Item - Servicio</Tableth2>
          </tr>
          <tr>
            <Tabletd2>Patente</Tabletd2>
            <Tabletd2>{!patent || patent === 'null' ? ' - ' : patent}</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>Segmento</Tabletd2>
            <Tabletd2>Automóvil</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>Ubicación</Tabletd2>
            <Tabletd2>{!regionName ? ' - ' : regionName}</Tabletd2>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

const Container = styled.div`
  padding: 32px 16px;
`;
const Table = styled.table` 
  tr:nth-child(even){background-color: #EBF1F5;}
`;
const Tableth2 = styled.th`
  text-align: start;
  background-color: #3D5176;
  padding: 14px 8px;
  color: #fff;
  font-size: 12px;
  font-family: 'Roboto medium';
  font-weight: 100;
`;
const Tabletd2 = styled.td`
  width: 10%;
  text-align: start;
  padding: 14px 8px;
  font-size: 12px;
  font-family: 'Roboto medium';
  font-weight: 100;
  color:#3D5176;
`;
