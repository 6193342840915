import styled from 'styled-components';
import { BlankSpace, ContainerScroll, Line } from '../genericComponents/layouts';
import RetomaPrice from '../genericComponents/RetomaPrice';
import {
  CardTitle, Text3, Text3M, Text4, Text4M,
} from '../genericComponents/texts';
import arrowRinght from '../assets/img/arrowRinght.png';
import pinMaker from '../assets/img/marcador-de-posicion.png';
import CardQuestions from '../genericComponents/CardQuestions';
import ligthImg from '../assets/img/ligth.svg';
import megaphoneImg from '../assets/img/megaphone.svg';
import kilometerClockImg from '../assets/img/kilometerClock.svg';
import carImg from '../assets/img/car.svg';
import carToolImg from '../assets/img/carTool.svg';
import moneySheetImg from '../assets/img/moneySheet.svg';
import bodyworkImg from '../assets/img/bodywork.svg';
import { priceFormat } from '../priceFormat';
import YearlyPrices from '../models/yearlyPrices.model';
import EquipmentPrices from '../models/equipmentPrices.model';

interface Props{
  vehicle:{
    completeName:string;
    shortName:string;
    autofactPrice:number;
    adjustSpecificVersion:number;
    year:number;
    equipment:string;
  }
  basePrices: YearlyPrices[]
  equipmentPrices: EquipmentPrices[]
  mileage:{
    adjustPrice:number;
    vehicleMilage:number;
    referenceMileage:number;
  }
  region:{
    adjustPrice:number;
    regionName:string;
  }
}

export default function AdjustBasePrice({
  basePrices, vehicle, equipmentPrices, mileage, region,
}:Props) {
  const minPrice = Math.min(...basePrices.map((p) => p.valor_base || 0));
  const maxPrice = Math.max(...basePrices.map((p) => p.valor_base || 0));
  const intervalChart = maxPrice !== minPrice ? (maxPrice - minPrice) / 2 : minPrice / 4;
  const startPriceChart = minPrice - intervalChart;
  const keyText = [0, 1, 2, 3];
  const keyEquipmentPrices = Array.from(Array(equipmentPrices.length).keys());
  const keyBasePrices = Array.from(Array(basePrices.length).keys());
  const percentageMileage = ((mileage.adjustPrice / vehicle.autofactPrice) * 100);
  const percentageRegion = ((region.adjustPrice / vehicle.autofactPrice) * 100);
  const currentBasePrice = basePrices.find((r) => r.ano_auto === vehicle.year)?.valor_base;

  return (
    <Container>
      <SubCont6>
        <SubCont7>
          <PaddingContainer>
            <CardTitle>
              Conoce el precio base Autofact en otros años
            </CardTitle>
            <RetomaPrice
              text="Precio base Autofact"
              price={priceFormat(currentBasePrice || 0)}
              position="flex-star"
            />
            <SubCont1>
              <YAxis>
                {Array(4).fill(0).map((_, i) => (
                  <Text4 key={keyText[i]}>
                    $
                    {priceFormat((maxPrice - (i * intervalChart)))}
                  </Text4>
                ))}
              </YAxis>
              <XAxis>
                {/* eslint-disable */}
                {basePrices.map(({ valor_base, ano_auto }, i) => (
                  <BarContainer key={keyBasePrices[i]}>
                    <RestSpace height={(maxPrice - (valor_base || 0))} />
                    <Bar height={(valor_base || 0 - startPriceChart)} />
                    <XLeyend>{ano_auto}</XLeyend> 
                  </BarContainer>
                ))}
                {/* eslint-enable */}
              </XAxis>
            </SubCont1>
          </PaddingContainer>
        </SubCont7>

        <SubCont8>
          <PaddingContainer>
            <CardQuestions
              icon={ligthImg}
              question="¿Qué es el precio base de un vehículo?"
              response={`Es el valor de mercado de un vehículo sin 
              considerar sus opciones de equipamiento específicas 
              como: llantas, airbag y otros. Según cada modelo y 
              sus características depende el valor base.`}
            />
            <CardQuestions
              icon={megaphoneImg}
              question="Es importante tener en cuenta"
              response={`El precio base puede diferir del 
              precio final de venta dependiendo de los 
              factores que verás a lo largo de este informe 
              (equipamiento, versión, año, entre otros). 
              Lee detenidamente esta herramienta de información 
              antes de tomar la decisión.`}
            />
          </PaddingContainer>
        </SubCont8>
      </SubCont6>
      <Line />
      <SubCont6>
        <SubCont7>
          <PaddingContainer>
            <CardTitle>
              Ajuste de precio según versión específica
            </CardTitle>
            <CardComparativeItem
              icon={carImg}
              title={vehicle.completeName}
              text="Total ajuste según versión específica:"
              price={priceFormat(vehicle.adjustSpecificVersion)}
            />
          </PaddingContainer>
        </SubCont7>

        <SubCont8>
          <PaddingContainer>
            <CardQuestions
              icon={ligthImg}
              question="¿Cómo se aplica el ajuste del precio?"
              response={`Se aplica un ajuste según versión
              específica cuando la versión del
              vehículo en cuestión posee distintas
              opciones de equipamiento u otras
              diferencias que influyen en su valor de
              mercado.`}
            />
            <CardQuestions
              icon={ligthImg}
              question="¿Cuáles diferencias aplican?"
              response={`Desde un cambio de generación del modelo vendido en el mismo año,
              diferencias en la carrocería u otras especificaciones de equipamiento.`}
            />
          </PaddingContainer>
        </SubCont8>
      </SubCont6>

      <SubCont6>
        <SubCont7>
          <PaddingContainer>
            <CardTitle>
              Ajuste de precio según equipamiento
            </CardTitle>
            <CardComparativeItem
              icon={carImg}
              title={vehicle.completeName}
              text="Ajuste total según opciones y equipamiento"
              price="0"
            />
            <SubCont2>
              <ContainerScroll>
                <InfoAutoSubCont>
                  <SubCont3>
                    <Text4M>{vehicle.shortName}</Text4M>
                  </SubCont3>
                  <AutoIconText text="Versión" icon={carImg} />
                  <AutoIconText text="Carrocería" icon={bodyworkImg} />
                  <AutoIconText text="Opciones y equipamiento" icon={carToolImg} />
                  <AutoIconText text="Ajuste por equipamiento" icon={moneySheetImg} />
                </InfoAutoSubCont>

                {equipmentPrices.map(({ version_m, equip, adjust_value, tipo_carroceria }, i) => ( // eslint-disable-line
                  <VehicleComparation
                    key={keyEquipmentPrices[i]}
                    isSelected={vehicle.equipment === equip}
                    version={version_m || 'Sin información'} // eslint-disable-line
                    bodywork={tipo_carroceria || 'Sin información'} // eslint-disable-line
                    optAndEquipment={equip || 'Sin información'}
                    adjustEquipment={priceFormat(adjust_value || 0)} // eslint-disable-line
                  />
                ))}
              </ContainerScroll>
            </SubCont2>

          </PaddingContainer>
        </SubCont7>

        <SubCont8>
          <PaddingContainer>
            <CardQuestions
              icon={ligthImg}
              question="¿Qué es el ajuste según equipamiento?"
              response={`El ajuste según equipamiento se aplica 
              en base a opciones de fábrica u otras diferencias 
              entre versiones, de acuerdo a la incidencia esperada 
              que tiene cada característica en el precio del vehículo. 
              Esta incidencia esperada se obtiene periódicamente a 
              partir del análisis de la información de mercado.`}
            />
          </PaddingContainer>
        </SubCont8>
      </SubCont6>
      <Line />
      <SubCont6>
        <SubCont7>
          <PaddingContainer>
            <CardTitle>
              Otros ajustes importantes
            </CardTitle>

            <SubCont9>
              <ImportantSettingsContainer>
                <ImportantSettingsSubCont1>
                  <img src={kilometerClockImg} width={45} height={45} alt="" />
                  <BlankSpace width="10px" />
                  <SubCont4>
                    <Text3>Ajustes por Kilometraje</Text3>
                    <BlankSpace height="5px" />
                    <TextImportantSettings>
                      {mileage.adjustPrice !== 0 ? `$${priceFormat(mileage.adjustPrice)}` : '¡Sin ajustes!'}
                    </TextImportantSettings>
                  </SubCont4>
                </ImportantSettingsSubCont1>
                <ImportantSettingsSubCont2>
                  <Text3M>Detalles del cálculo</Text3M>
                  <SubCont4>
                    <ItemSettings text="Kilometraje del vehículo" text2={mileage.vehicleMilage ? priceFormat(mileage.vehicleMilage) : '--'} />
                    <ItemSettings text="Kilometraje de referencia" text2={priceFormat(mileage.referenceMileage)} />
                    <ItemSettings text="Total de ajuste según porcentaje" text2={`${(percentageMileage).toFixed(2)}%`} />
                  </SubCont4>
                </ImportantSettingsSubCont2>
              </ImportantSettingsContainer>

              <ImportantSettingsContainer>
                <ImportantSettingsSubCont1>
                  <img src={pinMaker} width={40} height={40} alt="" />
                  <BlankSpace width="10px" />
                  <SubCont4>
                    <Text3>Ajustes por ubicación geográfica</Text3>
                    <BlankSpace height="5px" />
                    <TextImportantSettings>
                      {region.adjustPrice !== 0 ? `$${priceFormat(region.adjustPrice)}` : '¡Sin ajustes!'}
                    </TextImportantSettings>
                  </SubCont4>
                </ImportantSettingsSubCont1>
                <ImportantSettingsSubCont2>
                  <Text3M>Detalles del cálculo</Text3M>
                  <SubCont4>
                    <ItemSettings text="Ubicación seleccionada" text2={region.regionName ? `${region.regionName}` : '--'} />
                    <ItemSettings text="Total ajuste según ubicación geográfica" text2={`${(percentageRegion).toFixed(2)}%`} />
                  </SubCont4>
                </ImportantSettingsSubCont2>
              </ImportantSettingsContainer>
            </SubCont9>
          </PaddingContainer>
        </SubCont7>

        <SubCont8>
          <PaddingContainer>
            <CardQuestions
              icon={ligthImg}
              question="¿Qué es el ajuste por kilometraje?"
              response={`Es el ajuste de precio que utiliza como kilometraje 
              de referencia el promedio de kilometraje de vehículos con similares 
              características: en antigüedad y segmento.`}
            />
            <CardQuestions
              icon={ligthImg}
              question="¿Qué es el ajuste por ubicación geográfica?"
              response={`Es el ajuste aplicado según la ubicación geográfica, 
              en base a la diferencia de precio de vehículos similares en 
              antigüedad y segmento publicados en la región seleccionada, 
              según su valor de mercado general.`}
            />
          </PaddingContainer>
        </SubCont8>
      </SubCont6>
    </Container>
  );
}

function VehicleComparation(props:{isSelected?:boolean, version:string, bodywork:string, optAndEquipment:string, adjustEquipment:string}) {
  const {
    isSelected, version, bodywork, optAndEquipment, adjustEquipment,
  } = props;

  return (
    <InfoAutoSubCont>
      {isSelected && (
        <SubCont3>
          <Text3>Vehículo consultado</Text3>
        </SubCont3>
      )}
      <VehicleDataContainer isSelected={isSelected}>
        <VehicleDataSubCont>
          <Text4M>{version}</Text4M>
        </VehicleDataSubCont>
        <VehicleDataSubCont>
          <Text4M>{bodywork}</Text4M>
        </VehicleDataSubCont>
        <VehicleDataSubCont>
          <Text4M>{optAndEquipment}</Text4M>
        </VehicleDataSubCont>
        <VehicleDataSubCont>
          <Text4M>
            $
            {adjustEquipment}
          </Text4M>
        </VehicleDataSubCont>
      </VehicleDataContainer>
    </InfoAutoSubCont>
  );
}

function CardComparativeItem({
  title, text, price, icon,
}: { title: string, text: string, price: string, icon: string }) {
  return (
    <CardComparativeItemCont>
      <ContainerIcon>
        <img src={icon} width={35} height={35} alt="" />

      </ContainerIcon>
      <CardCompItemSubCont width="50px">
        <Text4M>{title}</Text4M>
      </CardCompItemSubCont>

      <img src={arrowRinght} width={20} height={20} alt="" />

      <CardCompItemSubCont width="200px">
        <Text4>{text}</Text4>
      </CardCompItemSubCont>

      <CardCompItemSubCont width="auto">
        <Text4M>
          $
          {price}
        </Text4M>

      </CardCompItemSubCont>
    </CardComparativeItemCont>
  );
}

function AutoIconText({ text, icon }: { text: string, icon: string }) {
  return (
    <AutoIconTextContainer>
      <img src={icon} width={30} height={30} alt="" />
      <BlankSpace width="5px" />
      <Text4>{text}</Text4>
    </AutoIconTextContainer>
  );
}

function ItemSettings({ text, text2 }: { text: string, text2: string }) {
  return (
    <ContainerItemSettings>
      <Text3>{text}</Text3>
      <Text3M>{text2}</Text3M>
    </ContainerItemSettings>
  );
}

const Container = styled.div`
  padding: 15px 0;
  @media (max-width: 700px){
    padding: 0px 15px;
  }
`;
const PaddingContainer = styled.div`
  padding: 0px 5px;
`;
const SubCont1 = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  margin: 20px auto;
  display: flex;
`;
const SubCont2 = styled.div`
  margin: 10px 0;
`;
const SubCont3 = styled.div`
  padding: 5px 0;
  display:flex;
  justify-content:flex-start;
  margin-bottom: 5px;
`;
const SubCont4 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 700px){
    flex: 1;
  }
`;
const SubCont6 = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 700px){
    flex-direction: column;
    border-bottom: none;
  }
`;
const SubCont7 = styled.div`
  flex: .7;
  max-width: 69%;
  margin: 10px 0px;
  @media (max-width: 700px){
    max-width: 100%;
  }
`;
const SubCont8 = styled.div`
  flex: .3;
  border-left: 1px solid #EBF1F5 ;
  @media (max-width: 700px){
    border-bottom: 1px solid #EBF1F5;
    border-left: none ;
  }
`;
const SubCont9 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 700px){
    flex-direction: column;
    justify-content: center;
  }
`;
const CardComparativeItemCont = styled.div`
  width: auto;
  border-radius: 10px;
  border: 1px solid #EBF1F5;
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  align-items: center;
`;
const CardCompItemSubCont = styled.div`
  width: ${({ width }: { width: string }) => width};
  display: flex;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 700px){
    flex: 1;
    margin: 0 5px;
  }
`;
const ContainerIcon = styled.div`
  display: flex;
  width: 100px;
  justify-content: center;
  @media (max-width: 700px){
    width: 65px;
  }
`;
const InfoAutoSubCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 5px;
`;
const AutoIconTextContainer = styled.div`
  width: 150px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const VehicleDataContainer = styled.div<{isSelected?:boolean}>`
  border-radius: 10px;
  border: ${(p) => (p.isSelected ? '1px solid #EBF1F5' : 'none')};
  margin-top: ${(p) => (p.isSelected ? '0' : '30px')};
  background-color: ${(p) => (p.isSelected ? '#fff9eb' : 'none')};
  display: flex;
  flex-direction: column;
  padding: 1px 15px;
  min-width: 80px;
  height: auto;
  align-items: center;
`;
const VehicleDataSubCont = styled.div`
  width: 100%;
  margin: 15px auto;
`;
const ImportantSettingsContainer = styled.div`
  margin: 10px 0;
  border: 1px solid #EBF1F5;
  border-radius: 10px;
  padding: 10px;
  width: 45%;
  @media (max-width: 700px){
    width: auto;
  }
`;
const ImportantSettingsSubCont1 = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  margin-top: 15px;
  justify-content: center;
  padding: 10px 0;
`;
const TextImportantSettings = styled.div`
  font-size: 25px;
  color: #3D5176;
  font-family: 'Roboto medium';
  @media (max-width: 700px){
    font-size: 16px;
  }
`;
const ImportantSettingsSubCont2 = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContainerItemSettings = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  margin: 10px 0;
`;
const YAxis = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;
const XAxis = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
`;
const BarContainer = styled.div`
  height: 100%;
  width: 14px;
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: relative;
`;
const RestSpace = styled.div<{height:number}>`
  display: flex;
  flex: ${(p) => p.height};
`;
const Bar = styled.div<{height:number}>`
  display: flex;
  flex: ${(p) => p.height};
  background: #155892;
  border-radius: 10px 10px 0 0;
`;
const XLeyend = styled(Text4)`
  width: 50px;
  position: absolute;
  bottom: -20px;
  transform: translateX(-19px);
  text-align: center;
`;
