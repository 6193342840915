import styled from 'styled-components';
import { BlankSpace } from '../genericComponents/layouts';
import ModalMoreInfo from './ModalMoreInfo';
import selloAutofactImg from '../assets/img/selloAutofact.svg';
import { Text4 } from '../genericComponents/texts';

// eslint-disable-next-line @typescript-eslint/ban-types
export default function AutofactStamp({ setVisible }:{setVisible:Function}) {
  return (
    <ModalMoreInfo setVisible={setVisible} textButton="¿Qué es el sello Autofact?" hideFooter>
      <ContainerBlue>
        <SubCont1>
          <img src={selloAutofactImg} width={25} height={25} alt="" />
          <BlankSpace width="10px" />
          <Title>
            ¿Qué es el sello Autofact?
          </Title>
        </SubCont1>
        <BlankSpace height="32px" />
        <Text4>
          En Autofact, tenemos información mensual de más de 100,000 vehículos al mes.
          Más de 70 empresas usan nuestros servicios y alrededor de 40,000
          personas confían en la información y tecnología que proveemos.
          El sello Autofact es garantía de nuestra expertise y es la
          representación gráfica de un equipo experto que lleva más de 10 años
          en la industria automotriz entregando precios a agentes del mercado,
          así como herramientas para la información y transferencia de vehículos
          que a su vez alimentan bases de datos capaces de determinar y proveer,
          con algoritmos y expertise, el valor comercial de cualquier vehículo
          usado en Chile.
        </Text4>
      </ContainerBlue>
    </ModalMoreInfo>
  );
}
const ContainerBlue = styled.div`
  height: 100vh;
  padding: 32px 16px;
  background: #EBF1F5;
  
`;
const Title = styled.label`
  font-size: 22px;
  color: #3D5176;
  font-family: 'Roboto medium';
`;
const SubCont1 = styled.div`
  display: flex;
  flex-direction: row;
`;
