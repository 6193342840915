import React from 'react';
import styled from 'styled-components';
import CardQuestions from '../genericComponents/CardQuestions';
import { CardTitle } from '../genericComponents/texts';
import ligthImg from '../assets/img/ligth.svg';
import { priceFormat } from '../priceFormat';

interface Props {
  residualPrices:{
    year?: number;
    vr_value?: number;
    uf_value?: number;
    currency_value?: number;
  }[]
}

export default function ResidualProjection({ residualPrices }:Props) {
  const Mensajes = {
    vr_value: 'Valor no encontrado',
  };
  return (
    <Container>
      <SubCont1>
        <PaddingContainer>

          <CardTitle>
            Proyección de valor residual nominal en CLP $
          </CardTitle>

          <Table>
            <tbody>
              <tr>
                <Tableth>Año</Tableth>
                <Tableth>Valor residual nominal %</Tableth>
                <Tableth>Valor residual nominal (CLP$)</Tableth>
              </tr>
              {residualPrices.map(({ year, vr_value: vrValue, currency_value: currencyValue }) => (
                <tr key={`${year} ${vrValue}`}>
                  <Tabletd>{year}</Tabletd>
                  <Tabletd>
                    {`${vrValue ? (vrValue * 100).toFixed(1) : Mensajes.vr_value}`}
                    %
                  </Tabletd>
                  <Tabletd>
                    $
                    {currencyValue ? priceFormat(currencyValue) : Mensajes.vr_value}
                  </Tabletd>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* <CardTitle>
            Valor residual real en UF
          </CardTitle>

          <Table>
            <tbody>
              <tr>
                <Tableth>Año</Tableth>
                <Tableth>Valor residual nominal %</Tableth>
                <Tableth>Valor residual nominal real (UF)</Tableth>
              </tr>
              {residualPrices.map(({ year, uf_value: ufValue }) => (
                <tr key={`${year} ${ufValue}`}>
                  <Tabletd>{year}</Tabletd>
                  <Tabletd>82%</Tabletd>
                  <Tabletd>
                    UF
                    {ufValue ? priceFormat(ufValue) : Mensajes.vr_value}
                  </Tabletd>
                </tr>
              ))}
            </tbody>
              </Table> */}
        </PaddingContainer>

      </SubCont1>

      <SubCont2>
        <PaddingContainer>
          <CardQuestions
            icon={ligthImg}
            question="¿Qué es la proyección de valor residual?"
            response={`Es la curva del valor residual de un 
            vehículo obtenido a partir de un modelo estadístico 
            que estima el comportamiento del precio en el tiempo. 
            Éste se obtiene a partir del profundo análisis de 
            publicaciones reales de distintas marcas y modelos 
            presentes en el mercado.`}
          />

          <CardQuestions
            icon={ligthImg}
            question="¿Qué es la proyección de valor residual nominal?"
            response={` El valor residual de un modelo corresponde al 
            valor porcentual actual respecto de su precio de lista o MSRP 
            (MSRP del inglés Manufacturer's Suggested Retail Price). 
            El precio de lista de cada modelo proviene del valor publicado 
            por los distintos concesionarios y dealers en el año respectivo 
            de venta de dicho modelo, o en su defecto de una estimación de 
            éste a partir del precio de otras versiones del mismo modelo en 
            el mismo año.`}
          />
        </PaddingContainer>
      </SubCont2>
    </Container>
  );
}

const Container = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px){
    flex-direction: column;
  }
`;
const PaddingContainer = styled.div`
  padding: 0px 10px;
`;
const SubCont1 = styled.div`
  flex: .6;
`;
const SubCont2 = styled.div`
  flex: .4;
`;
const Table = styled.table`
  margin: 20px 0;
  border: none;
  width: 85%;
  @media (max-width: 700px){
    width: 100%;
  }
`;

const Tableth = styled.th`
  font-size: 14px;
  color: #3D5176;
  font-family: 'Roboto medium';
  text-align: center;
  padding: 15px 0;
`;

const Tabletd = styled.td`
  font-size: 14px;
  color: #3D5176;
  font-family: 'Roboto medium';
  text-align: center;
  padding: 10px 0;
  @media (max-width: 700px){
    width: 10%;
    padding: 15px 0;
  }
`;
