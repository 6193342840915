import React from 'react';
import styled from 'styled-components';
import { CardTitle, Text4M } from '../genericComponents/texts';
import { BlankSpace } from '../genericComponents/layouts';
import selloAutofact from '../assets/img/selloAutofact.svg';
import ComparativeBar from '../genericComponents/ComparativeBar';
import CardWarning from '../genericComponents/CardWarning';
import CardQuestions from '../genericComponents/CardQuestions';
import ligthImg from '../assets/img/ligth.svg';
import personTagImg from '../assets/img/personTag.svg';
import keyImg from '../assets/img/key.svg';
import consensionaryImg from '../assets/img/consensionary.svg';
import RegularPrices from '../models/regularPrices.model';
import { priceFormat, roundPrice } from '../priceFormat';

interface Props{
  // eslint-disable-next-line @typescript-eslint/ban-types
  setAutofactStampVisible: Function;
  prices?: RegularPrices;
}

export default function ComparativeMarket({ setAutofactStampVisible, prices } : Props) {
  const Mensajes = {
    priceNotFound: 'Monto no encontrado',
  };

  return (
    <Container>
      <PaddingContainer>
        <SubCont2>
          <CardTitle>
            Según tipo de vendedor
          </CardTitle>

          <SubCont1 onClick={() => setAutofactStampVisible(true)}>
            <img src={selloAutofact} width={25} height={25} alt="" />
            <Text4M>Sello Autofact</Text4M>
          </SubCont1>

          <CardPriceComparative
            value1={`${prices && prices.min_value ? priceFormat(roundPrice(prices.min_value, 1)) : Mensajes.priceNotFound}`}
            value2={`${prices && prices.autofact_value ? priceFormat(roundPrice(prices.autofact_value, 1)) : Mensajes.priceNotFound}`}
            value3={`${prices && prices.max_value ? priceFormat(roundPrice(prices.max_value, 1)) : Mensajes.priceNotFound}`}
            select={2}
          />

          <ComparativeBar
            TriangleTop1={false}
            TriangleTop2
            TriangleTop3={false}
            TriangleBottom1={false}
            TriangleBottom2={false}
            TriangleBottom3={false}
          />
          <ProvidersComparative />
          <CardWarning
            text="Este tipo de precio según el tipo de vendedor se calculó en torno al precio de mercado Autofact"
          />
        </SubCont2>
        <SubCont3>
          <CardQuestions
            icon={ligthImg}
            question="Valor de mercado según vendedor"
            response={`Es el precio obtenido tras calcular 
            y exponer los valores de mercado según el 
            tipo de vendedor (particular o concesionario) 
            en base a un análisis estadístico de precios de 
            publicaciones tomando en cuenta el segmento del vehículo, 
            su antigüedad y su vendedor.`}
          />
        </SubCont3>
      </PaddingContainer>
    </Container>
  );
}

function CardPriceComparative({
  value1, value2, value3, select,
}: {
  value1: string, value2: string, value3: string, select: number
}) {
  return (
    <CardPriceComparativeConter>
      <CardPriceComparativeSubCont backgroundColor={select === 1 ? '#155892' : '#fff'}>
        <TextPrice
          color={select === 1 ? '#fff' : '#155892'}
        >
          $
          {value1}
        </TextPrice>
      </CardPriceComparativeSubCont>

      <CardPriceComparativeSubCont backgroundColor={select === 2 ? '#155892' : '#fff'}>
        <TextPrice
          color={select === 2 ? '#fff' : '#155892'}
        >
          $
          {value2}
        </TextPrice>
      </CardPriceComparativeSubCont>

      <CardPriceComparativeSubCont backgroundColor={select === 3 ? '#155892' : '#fff'}>
        <TextPrice
          color={select === 3 ? '#fff' : '#155892'}
        >
          $
          {value3}
        </TextPrice>
      </CardPriceComparativeSubCont>
    </CardPriceComparativeConter>
  );
}

function ProvidersComparative() {
  return (
    <ProvidersComparativeContainer>
      <ProvidersComparativeSubCont>
        <img src={personTagImg} width={20} height={20} alt="" />
        <BlankSpace height="5px" width="7px" />
        <TextPrice color="#14346f">Vendedor particular</TextPrice>
      </ProvidersComparativeSubCont>

      <ProvidersComparativeSubCont>
        <img src={keyImg} width={20} height={20} alt="" />
        <BlankSpace height="5px" width="7px" />

        <TextPrice color="#14346f">Precio de Mercado Autofact</TextPrice>
      </ProvidersComparativeSubCont>

      <ProvidersComparativeSubCont>
        <img src={consensionaryImg} width={20} height={20} alt="" />
        <BlankSpace height="5px" width="7px" />

        <TextPrice color="#14346f">Vendedor concesionario </TextPrice>
      </ProvidersComparativeSubCont>
    </ProvidersComparativeContainer>
  );
}

const Container = styled.div`
  padding: 10px 0;
`;

const PaddingContainer = styled.div`
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px){
    flex-direction: column;
  }
`;
const SubCont1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0 10px 0;
`;
const SubCont2 = styled.div`
  flex: .7;
  padding: 5px 15px;
  @media (max-width: 700px){
    padding: 0;
  }
`;
const SubCont3 = styled.div`
  flex: .3;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  @media (max-width: 700px){
    border-top: 1px solid #EBF1F5;
  }
`;
const CardPriceComparativeConter = styled.div`
  display: flex;
  justify-content: space-between;

`;
const CardPriceComparativeSubCont = styled.div`
  margin: 5px ;
  background-color:${({ backgroundColor }: { backgroundColor: string }) => backgroundColor}; 
  padding: 5px 10px;
  border-radius: 20px;
`;
const TextPrice = styled.label`
  font-size: 14px;
  font-family: 'Roboto medium';
  color:${({ color }: { color: string }) => color}; ;
  width: 100px;
  @media(max-width: 700px){
    text-align: center;
    font-size: 12px;
  }
`;
const ProvidersComparativeContainer = styled.div`
  display: flex;
  justify-content: space-around;

`;
const ProvidersComparativeSubCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 10px 5px;
  @media (max-width: 700px){
    flex-direction: column;
  }
`;
