import React from 'react';
import styled from 'styled-components';
import { Text3, Text4M } from './texts';
import { BlankSpace } from './layouts';

export default function CardQuestions({ icon, question, response }: { icon: string, question: string, response: string }) {
  return (
    <CardQuestionsCont>
      <QuestionsSubCont>
        <img src={icon} width={25} height={25} alt="" />
      </QuestionsSubCont>

      <QuestionsSubCont2>
        <Text4M>{question}</Text4M>
        <BlankSpace height="5px" />
        <Text3>{response}</Text3>
      </QuestionsSubCont2>
    </CardQuestionsCont>
  );
}

const CardQuestionsCont = styled.div`
  display: flex;
  flex-direction: row;
`;
const QuestionsSubCont = styled.div`
  padding: 14px 0 12px 16px;
`;

const QuestionsSubCont2 = styled.div`
  padding: 10px;
`;
