import styled from 'styled-components';
import { BlankSpace } from '../genericComponents/layouts';
import { CardTitle, Text4, Text5 } from '../genericComponents/texts';
import CardInfoDrop from './CardInfoDrop';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function VehicleInfo({
  patente,
}: { patente: string }) {
  console.log(patente);
  return (
    <Container>
      <CardTitle>
        Patente consultada:
        {' '}
        {patente}
      </CardTitle>
      <BlankSpace height="16px" />
      <Table>
        <tbody>
          <tr>
            <Tableth2>Estado</Tableth2>
            <Tableth2>Item - Servicio</Tableth2>
          </tr>
          <tr>
            <Tabletd2>ok</Tabletd2>
            <Tabletd2>Remates por accidentes</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>ok</Tabletd2>
            <Tabletd2>Revisión técnica vigente</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>ok</Tabletd2>
            <Tabletd2>Más revisiones técnicas</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>No aplica</Tabletd2>
            <Tabletd2>Registro de transporte</Tabletd2>
          </tr>
        </tbody>
      </Table>
      <BlankSpace height="16px" />
      <Table>
        <tbody>
          <tr>
            <Tableth2>Descripcion</Tableth2>
            <Tableth2>Actualización</Tableth2>
          </tr>
          <tr>
            <Tabletd2>No presenta pérdida total</Tabletd2>
            <Tabletd2>28/02/2022</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>Sin Información</Tabletd2>
            <Tabletd2>28/02/2022</Tabletd2>
          </tr>
          <tr>
            <Tabletd2>Abril</Tabletd2>
            <Tabletd2>28/02/2022</Tabletd2>
          </tr>
        </tbody>
      </Table>
      <SubCont2>
        <Text4>
          El vehículo en consulta no pertece al registro Nacional
          de Servicios de Transporte público de pasajeros ni al
          Registro Nacional de Servicios de Transporte
        </Text4>
      </SubCont2>
      <LineBlue />
      <CardInfoDrop title="Historial de revisión técnica">
        <Text5>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi ipsum corrupti soluta eveniet aliquid voluptas labore animi aliquam iste! Non, amet. Maiores consectetur inventore voluptas asperiores optio iusto nulla alias.
        </Text5>
      </CardInfoDrop>
      <LineBlue />
    </Container>
  );
}

const Container = styled.div`
  padding: 32px 16px;
`;
const Table = styled.table` 
  tr:nth-child(even){background-color: #EBF1F5;}
`;
const Tableth2 = styled.th`
  text-align: start;
  background-color: #3D5176;
  color: #fff;
  font-size: 12px;
  font-family: 'Roboto medium';
  font-weight: 100;
  padding: 14px 8px ;
`;
const Tabletd2 = styled.td`
  width: 10%;
  text-align: start;
  padding: 14px 8px ;
  font-size: 12px;
  font-family: 'Roboto medium';
  font-weight: 100;
  color:#3D5176;
`;
const SubCont2 = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 20px;
`;
const LineBlue = styled.div`
  width: 100%;
  height: 1px;
  background-color:#155892;
  margin: 5px 0;
`;
