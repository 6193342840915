import styled from 'styled-components';
import { BlankSpace } from '../genericComponents/layouts';
import { Text4 } from '../genericComponents/texts';
import selloAutofactImg from '../assets/img/selloAutofact.svg';

// eslint-disable-next-line @typescript-eslint/ban-types
export default function AutofactStampDesk({ visible, setVisible }:{visible:boolean, setVisible:Function}) {
  function close(event:React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    if (event.target === event.currentTarget) setVisible(false);
  }
  return (
    <ModalDeskContainer onClick={() => close} visible={visible}>
      <ModalDeskCard>
        <Close onClick={() => setVisible(false)}>&times;</Close>
        <SubCont1>
          <img src={selloAutofactImg} width={25} height={25} alt="" />
          <BlankSpace width="10px" />
          <Title>
            ¿Qué es el sello Autofact?
          </Title>
        </SubCont1>
        <BlankSpace height="32px" />
        <Text4>
          En Autofact, tenemos información mensual de más de 100,000 vehículos al mes.
          Más de 70 empresas usan nuestros servicios y alrededor de 40,000
          personas confían en la información y tecnología que proveemos.
          El sello Autofact es garantía de nuestra expertise y es la
          representación gráfica de un equipo experto que lleva más de 10 años
          en la industria automotriz entregando precios a agentes del mercado,
          así como herramientas para la información y transferencia de vehículos
          que a su vez alimentan bases de datos capaces de determinar y proveer,
          con algoritmos y expertise, el valor comercial de cualquier vehículo
          usado en Chile.
        </Text4>
      </ModalDeskCard>
    </ModalDeskContainer>
  );
}

const ModalDeskContainer = styled.div`
  display: ${({ visible }:{visible:boolean}) => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto;
  background: #EBF1F5DD;
`;

const ModalDeskCard = styled.div`
  background-color: #fefefe;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  background: #FFFFFF;
  border-radius: 10px;
`;
const Close = styled.span`
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
`;
const Title = styled.label`
  font-size: 22px;
  color: #3D5176;
  font-family: 'Roboto medium';
`;
const SubCont1 = styled.div`
  display: flex;
  flex-direction: row;
`;
