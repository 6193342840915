import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';
import Cookies from 'js-cookie';
import Footer from './components/Footer';
import Header from './components/Header';
import ConsultVehicle from './sections/ConsultVehicle';
import ExpectedResumePrice from './sections/ExpectedResumePrice';
import SectionCard from './components/SectionCard';
import SubHeader from './components/SubHeader';
import VehicleCharacteristics from './components/VehicleCharacteristics';
import VehicleInfo from './components/VehicleInfo';

import AutofactStamp from './components/AutofactStamp';
import useMediaQuery from './useMediaQuery';
import AutofactStampDesk from './components/AutofactStampDesk';
import ModalMoreInfo from './components/ModalMoreInfo';
import { apiBaseUrl, authorizationCookie } from './config/config';
import Vehicles from './models/vehicles.model';
import RegularPrices from './models/regularPrices.model';
import Publications from './models/publications.model';
import ResidualPrices from './models/residualPrices.model';
import MarketPrice from './sections/MarketPrice';
import AdjustBasePrice from './sections/AdjustBasePrice';
import ReferencePrice from './sections/ReferencePrice';
import Adjustments from './models/adjustments.model';
import EquipmentPrices from './models/equipmentPrices.model';
import YearlyPrices from './models/yearlyPrices.model';
import ComparativeMarket from './sections/ComparativeMarket';
import VersionPrices from './models/versionPrices.model';
import ResidualProjection from './sections/ResidualProjection';
import ConditionalPriceAdjustmen from './sections/ConditionalPriceAdjustmen';
import ConditionPrices from './models/conditionPrices.model';
import AboutPrice from './sections/AboutPrice';
import placeholder from './assets/img/placeholder_inf_precios.png';
import TradeInPrices from './models/tradeInPrices.model';
import PlateInfo from './models/plateInfo.model';

const Body = styled.div`
  padding: 16px;
`;

type imgFormat = {
  id_foto: number
  url_s3: string
}

type InfoType = {
  createdAt?:string;
  urlReport?:string;
  vehicle:Vehicles
  regular_price:RegularPrices
  publications:Publications
  residual_prices:ResidualPrices[]
  adjustments:Adjustments
  yearly_prices: YearlyPrices[]
  equipment_prices: EquipmentPrices[]
  version_prices:VersionPrices[]
  condition_prices:ConditionPrices
  seller_price?:RegularPrices
  model_images:string[]
  tradein_prices:TradeInPrices
  number_plate_info:PlateInfo
  public:boolean
}

const info = {
  vehicle: {},
  regular_price: {},
  publications: {},
  residual_prices: [{}],
  adjustments: {},
  yearly_prices: [{}],
  equipment_prices: [{}],
  version_prices: [{}],
  condition_prices: {},
  tradein_prices: {},
  number_plate_info: {},
} as InfoType;
export default function ReportViewer() {
  const [patenteInfoIsVisible, setPatenteInfoIsVisible] = useState(false);
  const [autofactStampVisible, setAutofactStampVisible] = useState(false);
  const [vehicleCharacteristics, setVehicleCharacteristics] = useState(false);
  const [infoJson, setInfoJson] = useState(info);

  const screenSize = useMediaQuery();
  const { id } = useParams();
  async function getJsonDataApi() {
    await fetch(`${apiBaseUrl}/price-report?id=${id}&jwt=${Cookies.get(authorizationCookie)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }).then((response) => response.json())
      .then((data) => {
        const res = data.data;
        const images:string[] = [];
        (res.images).forEach((element:imgFormat) => {
          images.push(element.url_s3);
        });
        res.model_images = images;
        res.seller_price.autofact_value = res.regular_price.autofact_value;
        setInfoJson(res);
      }).catch((err) => console.log(err));
  }

  useEffect(() => {
    getJsonDataApi();
  }, []);
  const vehicleCompleteName = `${infoJson.vehicle.brand || ''} ${infoJson.vehicle.model || ''} ${infoJson.vehicle.year || ''} ${infoJson.vehicle.version || ''}`;
  const vehicleShortName = `${infoJson.vehicle.brand || ''} ${infoJson.vehicle.model || ''} ${infoJson.vehicle.year || ''}`;
  if (patenteInfoIsVisible && screenSize < 700) {
    return (
      <ModalMoreInfo setVisible={setPatenteInfoIsVisible} textButton="INFORMACIÓN DE PATENTE">
        <VehicleInfo patente={infoJson.vehicle.patent ? infoJson.vehicle.patent : 'No aplica.'} />
      </ModalMoreInfo>
    );
  }
  if (vehicleCharacteristics && screenSize < 700) {
    return (
      <ModalMoreInfo setVisible={setVehicleCharacteristics} textButton="CARACTERÍSTICAS DEL VEHÍCULO">
        <VehicleCharacteristics />
      </ModalMoreInfo>
    );
  }
  if (autofactStampVisible && screenSize < 700) {
    return <AutofactStamp setVisible={setAutofactStampVisible} />;
  }
  const v = infoJson.vehicle.version ? infoJson.vehicle.version : '';
  const t = infoJson.vehicle.transmission ? infoJson.vehicle.transmission : '';
  const equipment = v.substring(v.indexOf(t));

  return (
    <div className="App">
      <Header />
      <SubHeader createdAt={infoJson.createdAt} id={id} urlReport={infoJson.urlReport} />
      <Body>
        <SectionCard title="Vehículo consultado" startsOpen>
          <ConsultVehicle
            vehicle={{
              carouselImages: infoJson.model_images ? infoJson.model_images : [
                placeholder,
              ],
              autofactPrice: infoJson.regular_price && infoJson.regular_price.autofact_value,
              completeName: vehicleCompleteName,
              patent: infoJson.number_plate_info.number,
              mileage: infoJson.vehicle.mileage,
              color: infoJson.vehicle.color,
              year: infoJson.vehicle.year,
              transmission: infoJson.vehicle.transmission,
              engine: infoJson.vehicle.engine,
              sinister: infoJson.number_plate_info.response?.remates.estado || false,
              technicalInspection: infoJson.number_plate_info.response?.revision_tecnica_vigente.estado || false,
              region: infoJson.vehicle.region,
              fuel: infoJson.vehicle.fuel,
              taxes: infoJson.vehicle.taxes,
              regionName: infoJson.adjustments.region_name,
            }}
            vehicleCharacteristics={vehicleCharacteristics}
            setVehicleCharacteristics={setVehicleCharacteristics}
            setAutofactStampVisible={setAutofactStampVisible}
          />
        </SectionCard>
        <SectionCard title="¿Cuál es su precio de mercado?">
          <MarketPrice
            setAutofactStampVisible={setAutofactStampVisible}
            prices={infoJson.regular_price}
            publication={infoJson.publications}
            referenceMileage={infoJson.vehicle.expected_mileage || 0}
            actualResidualPrice={infoJson.vehicle.residual_value || 0}
          />
        </SectionCard>
        <SectionCard title="¿Cuál es el rango de precio de retoma esperado?">
          <ExpectedResumePrice
            vehicle={{
              completeName: vehicleCompleteName,
              clientPrice: infoJson.tradein_prices.client_value,
              avgPrice: infoJson.tradein_prices.average_value,
              minPrice: infoJson.tradein_prices.min_value,
              maxPrice: infoJson.tradein_prices.max_value,
              rules: infoJson.tradein_prices.rules,
              region: infoJson.vehicle.region,
              public: infoJson.public,
            }}
          />
        </SectionCard>
        <SectionCard title={`¿Cuál es el precio base y ajustes para ${vehicleCompleteName}?`}>
          <AdjustBasePrice
            vehicle={{
              completeName: vehicleCompleteName,
              shortName: vehicleShortName,
              autofactPrice: infoJson.regular_price.autofact_value || 0,
              adjustSpecificVersion: infoJson.adjustments.specific_version_value || 0,
              year: infoJson.vehicle.year || 0,
              equipment: equipment || '',
            }}
            basePrices={infoJson.yearly_prices || []}
            equipmentPrices={infoJson.equipment_prices || []}
            mileage={{
              adjustPrice: infoJson.adjustments.mileage_value || 0,
              vehicleMilage: infoJson.vehicle.mileage || 0,
              referenceMileage: infoJson.vehicle.expected_mileage || 0,
            }}
            region={{
              adjustPrice: infoJson.adjustments.region_value || 0,
              regionName: infoJson.adjustments.region_name || '',
            }}
          />
        </SectionCard>
        <SectionCard title={`¿Cuáles son los precios de referencia para ${vehicleShortName}?`}>
          <ReferencePrice
            versionPrices={infoJson.version_prices || []}
            versionOwner={infoJson.yearly_prices.length > 0 ? infoJson.yearly_prices[0].version_m : ''}
          />
        </SectionCard>
        <SectionCard title="Ajuste de precio según condición del vehículo">
          <ConditionalPriceAdjustmen
            conditionPrices={infoJson.condition_prices}
          />
        </SectionCard>
        <SectionCard title="Conoce los precios comparativos de mercado">
          <ComparativeMarket
            setAutofactStampVisible={setAutofactStampVisible}
            prices={infoJson.seller_price}
          />
        </SectionCard>
        {infoJson.residual_prices
         && (
         <SectionCard title={`Conoce la proyección residual para ${vehicleCompleteName}`}>
           <ResidualProjection residualPrices={infoJson.residual_prices} />
         </SectionCard>
         )}
        <SectionCard title="¿Sabes cómo garantizamos y construimos el mejor precio?">
          <AboutPrice
            publications={infoJson.publications || []}
          />
        </SectionCard>
      </Body>
      <Footer />
      <AutofactStampDesk visible={autofactStampVisible} setVisible={setAutofactStampVisible} />
    </div>
  );
}
