import styled from 'styled-components';

export const SpacerContainer = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
`;

export const BlankSpace = styled.div<{width?:string, height?:string}>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color:#EBF1F5;

`;

export const ContainerScroll = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;
  min-width: 239px;
  margin-bottom: 5px;
  padding: 10px 0px;

  ::-webkit-scrollbar {
    height: 5px;
    
  }
  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color:#EBF1F5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color:#455b84;;
    
  }
`;
