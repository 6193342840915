import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import Tooltip from '@mui/material/Tooltip';
import { BlankSpace, SpacerContainer, Line } from '../genericComponents/layouts';
import {
  CardTitle, Text2, Text3, Text3M, Text4, Text4M, Text5M,
} from '../genericComponents/texts';
import infoImg from '../assets/img/info.svg';
import selloAutofact from '../assets/img/selloAutofact.svg';
// import reportImg from '../assets/img/report.svg';
import arrowLeftBlueImg from '../assets/img/arrowLeftBlue.svg';
import arrowRightBlueImg from '../assets/img/arrowRightBlue.svg';
import rightArrow from '../assets/img/right-arrow.png';
import upArrow from '../assets/img/upload.png';

import CardInfoDrop from '../components/CardInfoDrop';
import useMediaQuery from '../useMediaQuery';
import { priceFormat, roundPrice } from '../priceFormat';
import VehicleCharacteristics from '../components/VehicleCharacteristics';
import Taxes from '../models/taxes.model';

interface Props {
  vehicle:{
    completeName:string,
    autofactPrice?: number;
    carouselImages: string[];
    patent?: string;
    mileage?: number;
    color?: string;
    year?: number;
    transmission?: string;
    engine?: string;
    sinister: boolean;
    technicalInspection: boolean;
    region: number;
    regionName?: string;
    fuel?: string;
    taxes?:Taxes,
  };
  setVehicleCharacteristics: React.Dispatch<React.SetStateAction<boolean>>;
  vehicleCharacteristics: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setAutofactStampVisible: Function;
}

export default function ConsultVehicle(props:Props) {
  const {
    vehicleCharacteristics,
    setVehicleCharacteristics,
    setAutofactStampVisible,
  } = props;
  const windowSize = useMediaQuery();

  const Mensajes = {
    patente: 'No se ha encontrado patente.',
    autofactPrice: 'No se ha encontrado precio autofact.',
    transmission: 'No se ha encontrado transmision.',
    engine: 'No se ha encontrado motor.',
    color: 'No se ha encontrado color',
    mileage: 'No se ha encontrado kilometraje.',
    noApply: 'No aplica.',
  };
  const { vehicle } = props;
  const mileageData = vehicle.mileage ? priceFormat(vehicle.mileage) : Mensajes.mileage;
  const colorData = vehicle.color ? vehicle.color : Mensajes.color;
  return (
    <Container>
      <DSKContainer1>
        <PaddingContainer>
          <CardTitle>
            {vehicle.completeName}
          </CardTitle>
          <DSKDirection>
            <RecommendedPrice>
              <Text4>Precio recomendado por Autofact</Text4>
              <SpacerContainerPaddingLess>
                <SubCont1>
                  <BlankSpace height="4px" />
                  <Text5M>
                    $
                    {vehicle.autofactPrice ? priceFormat(roundPrice(vehicle.autofactPrice, 1)) : Mensajes.autofactPrice}
                  </Text5M>
                  <BlankSpace height="4px" />
                  <SubCont2>
                    <Text2>
                      Este es el precio que nosotros te recomendamos, esperado para vehículo en condiciones normales
                    </Text2>
                    <BlankSpace width="10px" />
                    <Tooltip arrow title="El vehículo presenta problemas estéticos menores y está en perfecto estado mecánico. ">
                      <img src={infoImg} width={16} height={16} alt="" />
                    </Tooltip>
                  </SubCont2>
                </SubCont1>
                <SubCont3 onClick={() => setAutofactStampVisible(true)}>
                  <img src={selloAutofact} width={25} height={25} alt="" />
                  <Text3M>Sello Autofact</Text3M>
                  <Text3><u>¿Quieres saber más?</u></Text3>
                </SubCont3>
              </SpacerContainerPaddingLess>
            </RecommendedPrice>

            <BlankSpace height="16px" />

            {windowSize < 700 ? (
              <>
                <Carousel
                  showStatus={false}
                  showArrows
                  swipeable
                  renderArrowPrev={
                    (onClickHandler, hasPrev) => (hasPrev ? (
                      <ArrowLeft onClick={onClickHandler}>
                        <img src={arrowLeftBlueImg} width={26} height={26} alt="" />
                      </ArrowLeft>
                    ) : null)
                  }
                  renderArrowNext={
                    (onClickHandler, hasNext) => (hasNext ? (
                      <ArrowRight onClick={onClickHandler}>
                        <img src={arrowRightBlueImg} width={26} height={26} alt="" />
                      </ArrowRight>
                    ) : null)
                  }
                >
                  {vehicle.carouselImages.map((image, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <img src={image} key={index} alt="" />
                  ))}
                </Carousel>
                <BlankSpace height="16px" />
              </>
            ) : null}

            <CarDetails>
              <CardDetailItem title="Patente" value={vehicle.patent && vehicle.patent !== 'null' ? vehicle.patent : Mensajes.noApply} />
              <CardDetailItem title="Kilometraje" value={mileageData} />
              <CardDetailItem title="Color" value={vehicle.patent ? colorData : Mensajes.noApply} />
              <CardDetailItem title="Año" value={`${vehicle.year}`} />
              <CardDetailItem title="Motor" value={vehicle.engine ? vehicle.engine : Mensajes.engine} />
              <CardDetailItem title="Transmisión" value={vehicle.transmission ? vehicle.transmission : Mensajes.transmission} />
            </CarDetails>
          </DSKDirection>

        </PaddingContainer>
        <BlankSpace height="10px" />
        <Line />
        <BlankSpace height="20px" />

        <CardInfoDrop title="Más información del vehículo">
          <ModalMoreInfoCont onClick={() => setVehicleCharacteristics((r) => !r)}>
            <BarTitle>
              <Text4M>CARACTERÍSTICAS DEL VEHÍCULO</Text4M>
              <img width={12} height={12} src={vehicleCharacteristics ? upArrow : rightArrow} alt="" />
            </BarTitle>
            {vehicleCharacteristics && (<VehicleCharacteristics engine={vehicle.engine} transmission={vehicle.transmission} fuel={vehicle.fuel} mileage={vehicle.mileage} color={vehicle.color} patent={vehicle.patent} taxes={vehicle.taxes} regionName={vehicle.regionName} />)}
          </ModalMoreInfoCont>
        </CardInfoDrop>

      </DSKContainer1>
      {windowSize >= 700 ? (
        <DSKcontainer2>
          <Carousel
            showStatus={false}
            showArrows
            swipeable
            renderArrowPrev={
                (onClickHandler, hasPrev) => (hasPrev ? (
                  <ArrowLeft onClick={onClickHandler}>
                    <img src={arrowLeftBlueImg} width={26} height={26} alt="" />
                  </ArrowLeft>
                ) : null)
              }
            renderArrowNext={
                (onClickHandler, hasNext) => (hasNext ? (
                  <ArrowRight onClick={onClickHandler}>
                    <img src={arrowRightBlueImg} width={26} height={26} alt="" />
                  </ArrowRight>
                ) : null)
              }
          >
            {vehicle.carouselImages.map((image, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <img src={image} key={index} alt="" />
            ))}
          </Carousel>
        </DSKcontainer2>
      ) : null}

    </Container>
  );
}

function CardDetailItem({ title, value }: { title: string, value: string }) {
  return (
    <CarDetailsCont>
      <Text4M>{title}</Text4M>
      <BlankSpace height="4px" />
      <Text4>{value}</Text4>
    </CarDetailsCont>
  );
}

const Container = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
const PaddingContainer = styled.div`
  padding: 0px 10px;
`;
const RecommendedPrice = styled.div`
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  background: #EBF1F5;
`;
const SpacerContainerPaddingLess = styled(SpacerContainer)`padding:0;`;
const SubCont1 = styled.div`
  display: flex;
  flex-direction: column;
`;
const SubCont2 = styled.div`
  display: flex;
`;
const SubCont3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 120px;
  margin-left: 30px;
`;
// const SubCont4 = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;
const CarDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const CarDetailsCont = styled.div`
  width: 30%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
`;
// const CarDetailSubCont1 = styled.div`
//   width: 30%;
//   display: flex;
//   flex-direction: column;
// `;
const ArrowLeft = styled.button`
  position: absolute;
  width: 32px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  z-index: 100;
`;
const ArrowRight = styled.button`
  position: absolute;
  width: 32px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  z-index: 100;
`;
/*
*const Tabletd = styled.td`
*  text-align: start;
*  width: 10%;
*  padding-bottom: 15px;
*
*`;
*const Tableth = styled.th` text-align: start;`;
*/
const ModalMoreInfoCont = styled.div`
  border: 1.5px solid #155892;
  justify-content: space-between;
  padding: 15px 10px ;
  border-radius: 5px;
  margin: 10px 0px;
`;
const DSKContainer1 = styled.div`
  width: 70%;
  @media (max-width: 700px) {
    width: 100%;
  }
`;
const DSKcontainer2 = styled.div`
  width: 40%;
`;
const DSKDirection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
const BarTitle = styled.div`
  justify-content: space-between;
  display: flex;
`;
