import styled from 'styled-components';
import { Text3 } from './texts';
import warning from '../assets/img/warning.png';
import { BlankSpace } from './layouts';

export default function CardWarning({ text }:{text:string}) {
  return (
    <CadrWarningContainer>
      <img src={warning} width={25} height={25} alt="" />
      <BlankSpace width="15px" />
      <Text3>{text}</Text3>
    </CadrWarningContainer>
  );
}

const CadrWarningContainer = styled.div`
  max-width: 700px;
  background-color: #fdf0d7;
  border: 2px solid #FFC048;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin: 20px 0;
`;
