import React, { useState } from 'react';
import styled from 'styled-components';
import { BlankSpace } from '../genericComponents/layouts';
import arrowDownImg from '../assets/img/arrowDown.svg';
import arrowUpImg from '../assets/img/arrowUp.svg';
import { Text5M } from '../genericComponents/texts';

interface Props {
  title: string,
  children?: React.ReactNode,
  startsOpen?:boolean,
  arrowVisible?: boolean,
}

export default function SectionCard({
  title, children, startsOpen = false, arrowVisible = true,
}: Props) {
  const [isOpen, setisOpen] = useState(startsOpen);

  return (
    <Container>
      <SpacerContainer onClick={() => setisOpen(!isOpen)}>
        <Text5M>{title}</Text5M>
        <BlankSpace width="32px" />
        {(arrowVisible)
          ? (
            <ButtonWrapper>
              <img width={26} height={26} src={isOpen ? arrowDownImg : arrowUpImg} alt="" />
            </ButtonWrapper>
          )
          : '' }
      </SpacerContainer>
      <Collapsable className={!isOpen ? 'closed' : 'opened'}>
        {children}
      </Collapsable>
    </Container>
  );
}

const Container = styled.section`
  width: 95%;
  max-width: 1000px;
  margin: 16px  auto 0 auto;

  .opened{
    transition: max-height 0.5s ease-in;
    max-height: auto;
  }
  .closed{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.20s ease-out;
    padding: 0;
  }

  @media (max-width: 700px){
    width: 100%;
  }
`;
const Collapsable = styled.div`
  box-shadow: 0px 4px 10px 0px rgba(143, 171, 195, 0.3);
  border-radius: 10px;
  padding-Top: 10px;
  padding: 0 15px;
  @media (max-width: 700px){
    padding: 0px;
  }
  
`;
const ButtonWrapper = styled.button`
  display: flex;
  background: none;
  border: none;
  outline: none;
`;
export const SpacerContainer = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
