import styled from 'styled-components';
import {
  CardTitle, Text3, Text3M, Text4M,
} from '../genericComponents/texts';
import CardWarning from '../genericComponents/CardWarning';
import { BlankSpace, ContainerScroll } from '../genericComponents/layouts';
import carImg from '../assets/img/car.svg';
import bodyworkImg from '../assets/img/bodywork.svg';
import moneyCircleImg from '../assets/img/moneyCircle.svg';
import { priceFormat } from '../priceFormat';

type versiones = {
  marca?: string;
  modelo?: string;
  ano_auto?: number;
  version_m?: string;
  tipo_carroceria?: string;
  valor_base?: number;
};

interface Props {
  versionPrices: Array<versiones> ;
  versionOwner?: string;
}

const Mensajes = {
  priceNotFound: 'No se ha encontrado precio.',
};

export default function ReferencePrice({ versionPrices, versionOwner }: Props) {
  const groupByFive = (array: Array<versiones>) => {
    const grouped = [];
    for (let i = 0; i < array.length; i += 5) {
      grouped.push(array.slice(i, i + 5));
    }
    return grouped;
  };

  const groupedVersions = groupByFive(versionPrices);

  return (
    <Container>
      <PaddingContainer>
        <CardTitle>Te mostramos el precio de distintas versiones</CardTitle>

        <div>
          {groupedVersions.map((group) => (
            <ContainerScroll key={`${group}`}>
              {group.map(
                (version: versiones) => (
                  <CardVersionItem
                    key={`${version.marca} ${version.modelo} ${version.ano_auto} ${version.version_m} ${version.tipo_carroceria}`}
                    name={`${version.marca} ${version.modelo} ${version.ano_auto}`}
                    version={version.version_m}
                    Carroceria={version.tipo_carroceria}
                    price={
                      version.valor_base
                        ? priceFormat(version.valor_base)
                        : Mensajes.priceNotFound
                    }
                    select={version.version_m === versionOwner}
                  />
                ),
              )}
            </ContainerScroll>
          ))}
        </div>

        <CardWarning text="Ten presente que el valor base no considera el equipamiento específico." />
      </PaddingContainer>
    </Container>
  );
}

function CardVersionItem({
  name,
  version,
  Carroceria,
  price,
  select,
}: {
  name?: string;
  version?: string;
  Carroceria?: string;
  price?: string;
  select?: boolean;
}) {
  return (
    <div>
      {select ? (
        <ContainerTextItem>
          <Text3M>Vehículo consultado</Text3M>
        </ContainerTextItem>
      ) : (
        <BlankSpace height="25px" />
      )}
      <CardVersionItemContainer backgroundColor={select ? '#fff9eb' : '#fff'}>
        <CardVersionItemSubCont>
          <Text4M>{name}</Text4M>
        </CardVersionItemSubCont>

        <Line />

        <CardVersionItemSubCont>
          <SubCont1>
            <img src={carImg} width={30} height={30} alt="" />
            <BlankSpace width="16px" />
            <SubCont2>
              <Text3M>Versión</Text3M>
              <Text3>{version}</Text3>
            </SubCont2>
          </SubCont1>
          <BlankSpace height="10px" />

          <SubCont1>
            <img src={bodyworkImg} width={30} height={30} alt="" />
            <BlankSpace width="16px" />
            <SubCont2>
              <Text3M>Carrocería</Text3M>
              <Text3>{Carroceria}</Text3>
            </SubCont2>
          </SubCont1>
        </CardVersionItemSubCont>

        <Line />

        <CardVersionItemSubCont2>
          <img src={moneyCircleImg} width={22} height={22} alt="" />
          <BlankSpace width="5px" />
          <SubCont2>
            <Text3>Precio base</Text3>
            <Text4M>
              $
              {price}
            </Text4M>
          </SubCont2>
        </CardVersionItemSubCont2>
      </CardVersionItemContainer>
    </div>
  );
}

const Container = styled.div`
  padding: 10px 0;
`;
const PaddingContainer = styled.div`
  padding: 0px 10px;
`;

const SubCont1 = styled.div`
  display: flex;
  flex-direction: row;
`;

const SubCont2 = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardVersionItemContainer = styled.div`
  height: auto;
  max-width: 160px;
  border: 2px solid #ebf1f5;
  margin: 0 10px;
  background-color: ${({ backgroundColor }: { backgroundColor: string }) => backgroundColor};
`;

const CardVersionItemSubCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px 16px;
`;

const CardVersionItemSubCont2 = styled(CardVersionItemSubCont)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ebf1f5;
`;

const ContainerTextItem = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
`;
