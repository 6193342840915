import styled from 'styled-components';
import { CardTitle, Text3 } from '../genericComponents/texts';
import { b2bBaseUrl } from '../config/config';

interface Props{
  publicationsList:{
    precio?: number;
    ss_path?: string;
    fecha_est?: string;
    url_respaldo?: string;
  }[]
}

export default function publicationsLinks({ publicationsList }:Props) {
  return (
    <Container>
      <SubCont1>
        <PaddingContainer>

          <Text3>
            A continuación se encuentran los links de las publicaciones desde
            las cuales se obtuvo el precio para el auto en cuestión. Debido a
            que nuestro sistema basa su proceso en un mes móvil de información,
            existen publicaciones que ya no están vigentes al minuto de
            solicitar un precio. Para estos casos existe una imagen relacionada
            que muestra la publicación que ya no está activa.
          </Text3>

        </PaddingContainer>
      </SubCont1>

      <SubCont1>
        <PaddingContainer>
          <CardTitle>
            Publicaciones de referencia para obtención de precio
          </CardTitle>
        </PaddingContainer>
      </SubCont1>

      <SubCont2>
        <PaddingContainer>
          {publicationsList.map(({ url_respaldo: urlRespaldo, ss_path: ssPath }) => (
            <CardPublicationslist
              key={ssPath}
              urlRespaldo={urlRespaldo}
              ssPath={ssPath}
            />
          ))}
        </PaddingContainer>
      </SubCont2>

    </Container>
  );
}

function CardPublicationslist({
  urlRespaldo, ssPath,
} : {
  urlRespaldo?: string, ssPath?: string
}) {
  const ir = `${b2bBaseUrl}/stock/valida_link?r=https://s3-sa-east-1.amazonaws.com/respaldo-fotos-extraccion/${ssPath}&q=${urlRespaldo}`;
  // consultamos si desde el endpoint viene el nombre de la captura de la publicación
  if (ssPath && ssPath !== '0') {
    return (
      <p>
        <Enlace href={ir}>{urlRespaldo}</Enlace>
      </p>
    );
  }
  return (
    <p>
      <EnlaceNoDisponible>
        {urlRespaldo}
        {' '}
        (Enlace no disponible)
      </EnlaceNoDisponible>
    </p>
  );
}

const Enlace = styled.a`
  color: #4189d0;
  font-weight: lighter;
  font-size: 12px;
`;

const EnlaceNoDisponible = styled.a`
  color: #4189d0;
  font-weight: lighter;
  font-size: 12px;
  text-decoration: underline;
`;

const Container = styled.div`
  padding: 10px 0;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
const PaddingContainer = styled.div`
  padding: 10px 20px;
  @media (max-width: 700px) {
    padding: 10px 10px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ebf1f5;
`;
const SubCont1 = styled.div`
  margin: 10px 10px;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
const SubCont2 = styled.div`
  margin: 10px 10px;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
