import React from 'react';
import styled from 'styled-components';
import logoImg from '../assets/img/logo.png';
import menuImg from '../assets/img/menu.svg';

interface HeaderProps {
  withActions?: boolean;
}

export default function Header({ withActions = false }: HeaderProps) {
  return (
    <CustomHeader>
      <ButtonWrapper>
        <img height={45} src={logoImg} alt="autofact" />
      </ButtonWrapper>
      { withActions
        ? (
          <ButtonWrapper>
            <img width={24} height={21} src={menuImg} alt="" />
          </ButtonWrapper>
        ) : ''}
    </CustomHeader>
  );
}

const CustomHeader = styled.header`
  height: 60px;
  padding: 0 16px;
  background: #155892;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const ButtonWrapper = styled.button`
  background: none;
  border: none;
  outline: none;
`;
