import { useState, useEffect } from 'react';

export default function useMediaQuery() {
  const [size, setSize] = useState(window.innerWidth);

  useEffect(() => {
    const listener = () => setSize(window.innerWidth);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return size;
}
