import styled from 'styled-components';
import Cookies from 'js-cookie';
import { useState } from 'react';
import subHeaderMaskImg from '../assets/img/subHeaderMask.svg';
import { SpacerContainer } from '../genericComponents/layouts';
import { apiBaseUrl, authorizationCookie, redirectUrlConces } from '../config/config';

interface HeaderProps {
  withActions?: boolean;
  id?: string;
  createdAt?:string;
  urlReport?:string;
}

export default function SubHeader({
  withActions = true, id = '0', createdAt = '', urlReport = '',
}: HeaderProps) {
  let generatingReport = 0;
  const buttonText = {
    loading: 'GENERANDO PDF...',
    generate: 'INFORME PDF',
  };
  const [currentText, setCurrentText] = useState(buttonText.generate);

  async function exportPriceReport() {
    if (generatingReport === 0) {
      generatingReport = 1;
      setCurrentText(buttonText.loading);
      if (urlReport && urlReport !== '') {
        window.open(urlReport, '_blank', 'noopener,noreferrer');
      } else {
        await fetch(`${apiBaseUrl}/export/price-report/${id}?jwt=${Cookies.get(authorizationCookie)}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        }).then((response) => response.json())
          .then((data) => {
            console.log(data);
            window.open(data.data, '_blank', 'noopener,noreferrer');
          }).catch((err) => console.log(err));
      }
      setCurrentText(buttonText.generate);
      generatingReport = 0;
    }
  }

  async function redirectPrecio() {
    window.open(redirectUrlConces, '_blank', 'noopener,noreferrer');
  }

  return (
    <Container>
      <DSKSubContainer>
        <DSKContainer2>
          <Mask src={subHeaderMaskImg} />
          <Title>Informe de precio: Tasación de auto usado</Title>
          <DSKContainer1>
            <P>
              ID del Informe:
              { ' ' }
              { id }
            </P>
            <P>
              Fecha de emisión:
              { ' ' }
              { createdAt }
            </P>
          </DSKContainer1>
        </DSKContainer2>
        { withActions
          ? (
            <DSKContainer3>
              <SpacerContainer>
                { /* <Button>INFORME CLIENTE</Button> */ }
                <Button onClick={() => exportPriceReport()}>{currentText}</Button>
              </SpacerContainer>
              <SpacerContainer>
                { /* <TextButton>Compartir Informe</TextButton> */ }
                <TextButton onClick={() => redirectPrecio()}>Volver a Buscar</TextButton>
              </SpacerContainer>
            </DSKContainer3>
          ) : '' }
      </DSKSubContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 16px;
  background-color: #0B3254;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DSKSubContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 700px){
    flex-direction: column;
  }
`;
const Mask = styled.img`
  top: 0;
  right: 0;
  position: absolute;
  z-index: -1;
`;
const Title = styled.label`
  display: flex;
  width: 300px;
  font-size: 28px;
  font-family: 'Roboto bold';
  color: #fff;
`;
const P = styled.p`
  color: #FFF;
  font-size: 11px;
`;
const Button = styled.button`
  height: 40px;
  padding: 10px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  border-radius: 4px;
  color: #155892;
  font-size: 20px;
  font-family: 'Roboto medium';
  @media (max-width: 700px){
    margin: 0;
  }
`;
const TextButton = styled.button`
  color: #FFA401;
  font-size: 16px;
  font-family: 'Roboto bold';
  padding: 8px;
`;
const DSKContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 700px){
    flex-direction: row;
    justify-content: space-between;
  }
`;
const DSKContainer2 = styled.div`
`;
const DSKContainer3 = styled.div`
`;
