import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReportViewer from './ReportViewer';
import ReportViewerPDF from './ReportViewerPDF';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/ver/:id" element={<ReportViewer />} />
        <Route path="/pdf/:id" element={<ReportViewerPDF />} />
        <Route path="/pdf/:id/:configLinks" element={<ReportViewerPDF />} />
        <Route
          path="/forbidden"
          element={<p>No autorizado</p>}
        />
        <Route
          path="*"
          element={<p>Ruta no encontrada</p>}
        />
      </Routes>
    </BrowserRouter>
  );
}
