import ENVIRONMENT from '../environment.json';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const env: any = ENVIRONMENT;
const authorizationCookie = env.REACT_APP_AUTHORIZATION_COOKIE || 'access_token';
const redirectUrlConces: string = env.REACT_APP_REDIRECT_URL_CONCES || 'https://cl.autofact.qa/';
const apiBaseUrl: string = env.API_BASE_URL || 'https://purchase-api-price-report-gateway.retoma.qa.autofact.app/v1';
const b2bBaseUrl: string = env.B2B_BASE_URL || 'https://testing.cl.autopress.cl';

export {
  authorizationCookie,
  redirectUrlConces,
  apiBaseUrl,
  b2bBaseUrl,
};
