import { useState } from 'react';
import styled from 'styled-components';
import { CardTitle, Text3, Text4 } from '../genericComponents/texts';
import { BlankSpace } from '../genericComponents/layouts';
import stockImg from '../assets/img/stock.svg';
import Publications from '../models/publications.model';
import { priceFormat } from '../priceFormat';

interface Props{
  publications:Publications
}

function getSquareDiff(array: number[], mean:number) {
  const squareDiff: number[] = [];
  array.forEach((element) => {
    squareDiff.push((element - mean) ** 2);
  });
  let add = 0;
  squareDiff.forEach((element) => {
    add += element;
  });
  return add;
}

function getStandardDeviationCalc(array: number[]) {
  const n = array.length;
  const mean = array ? array.reduce((a, b) => a + b, 0) / n : 0;
  if (mean === 0) {
    return 'No informado';
  }
  const squareDiff = getSquareDiff(array, mean);
  if (!squareDiff) {
    return 'No informado';
  }
  const standardDeviation = squareDiff ? Math.sqrt(squareDiff / n) : 0;
  return standardDeviation;
}

function getConfidenceInterval(array: number[], level:number) {
  const n = array.length;
  const mean = array ? array.reduce((a, b) => a + b, 0) / n : 0;
  if (mean === 0) {
    return 'No informado';
  }
  const std = getStandardDeviationCalc(array);
  const standardDeviation:number = std === 'No informado' ? 0 : std;
  if (standardDeviation === 0) {
    return 'No informado';
  }
  const upper = Math.round(mean + (level * (standardDeviation / Math.sqrt(n))));
  const lower = Math.round(mean - (level * (standardDeviation / Math.sqrt(n))));
  return `(${priceFormat(lower)} - ${priceFormat(upper)})`;
}

export default function AboutPrice({ publications }:Props) {
  const publicationsList = publications.list || [];
  const minimunPrice = Math.min(...publicationsList.map((publication) => publication.precio || 0));
  const maximunPrice = Math.max(...publicationsList.map((publication) => publication.precio || 0));
  const priceArray = publicationsList.map((a) => a.precio) || [0, 0];
  // console.log(getStandardDeviationCalc(priceArray));
  const standardDeviation = getStandardDeviationCalc(priceArray);
  const confidenceInterval = getConfidenceInterval(priceArray, 1.96);
  return (
    <Container>
      <SubCont5>
        <PaddingContainer>
          <CardTitle>
            ¿Qué hacemos?
          </CardTitle>

          <SubCont1>
            <Separator>
              <img src={stockImg} width={40} height={40} alt="" />
            </Separator>
            <SubCont2>
              <Text3>Entregamos el precio de mercado de un vehículo usado. Basándonos en la información de oferta actual y de los últimos meses publicada para este vehículo en medios impresos y digitales.</Text3>
            </SubCont2>
          </SubCont1>

          <Line />
          <CardInfoDesplegable
            number={1}
            title="¡Buscamos! y registramos todas las publicaciones encontradas"
            info={`Nuestro equipo y herramientas tecnológicas detectan 
            constantemente todas las publicaciones de venta de vehículos 
            y éstas quedan organizadas en bases de datos.`}
          />
          <CardInfoDesplegable
            number={2}
            title="Hacemos un análisis exhaustivo"
            info={`Mediante cálculos y un equipo experto, 
            analizamos e interpretamos las bases de datos para 
            generar resultados de precios.`}
          />
          <CardInfoDesplegable
            number={3}
            title="Generamos resultados a través de modelos matemáticos"
            info={`Gracias a un modelo matemático, se generan los precios 
            base y se construye el precio final en base a todas las variables 
            mencionadas en este informe.`}
          />
        </PaddingContainer>

      </SubCont5>

      <SubCont6>
        <PaddingContainer>
          <CardTitle>
            ¿Cómo se hizo el cálculo?
          </CardTitle>

          <SubCont4>
            <Text4>Resumen estadístico</Text4>
            <BlankSpace height="10px" />
            <Table>
              <tbody>

                <Tabletr>
                  <Tableth>Publicaciones vigentes</Tableth>
                  <Tableth>{publications.list?.length}</Tableth>
                </Tabletr>
                <tr>
                  <Tabletd>Precio máximo</Tabletd>
                  <Tabletd>
                    {maximunPrice === -Infinity || maximunPrice === Infinity ? 'No informado' : `$${priceFormat(maximunPrice)}`}
                  </Tabletd>
                </tr>
                <tr>
                  <Tabletd>Precio mínimo</Tabletd>
                  <Tabletd>
                    {minimunPrice === -Infinity || minimunPrice === Infinity ? 'No informado' : `$${priceFormat(minimunPrice)}`}
                  </Tabletd>
                </tr>
                <tr>
                  <Tabletd>
                    {'Desviación estándar   '}
                  </Tabletd>
                  <Tabletd>
                    {standardDeviation === 'No informado' ? standardDeviation : `$${priceFormat(standardDeviation)}`}
                  </Tabletd>
                </tr>
                <tr>
                  <Tabletd>
                    {'Intervalo de confianza  '}
                  </Tabletd>
                  <Tabletd>
                    {confidenceInterval === 'No informado' ? 'No informado' : `$${confidenceInterval}`}
                  </Tabletd>
                </tr>
              </tbody>
            </Table>
          </SubCont4>
        </PaddingContainer>
      </SubCont6>
    </Container>
  );
}

function CardInfoDesplegable({ number, title, info }: { number: number, title: string, info: string }) {
  const [isOpen, setisOpen] = useState(true);
  return (
    <InfoDesplegableContainer onClick={() => setisOpen(!isOpen)}>
      <InfoDesplegableSubCont>

        <NumberIndicator>
          <Text4>{number}</Text4>
        </NumberIndicator>
        <BlankSpace width="10px" />
        <SubCont3>
          <Text3>{title}</Text3>
        </SubCont3>
        <BlankSpace width="5px" />
      </InfoDesplegableSubCont>
      <Collapsable className={!isOpen ? 'closed' : 'opened'}>
        <Text3>{info}</Text3>
      </Collapsable>
    </InfoDesplegableContainer>
  );
}

const Container = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px){
    flex-direction: column;
  }
`;
const PaddingContainer = styled.div`
  padding: 10px 20px;
  @media (max-width: 700px){
    padding: 10px 10px;
  }
`;
const SubCont1 = styled.div`
  margin: 10px 10px;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px){
    flex-direction: column;
  }
`;
const SubCont2 = styled.div``;
const SubCont3 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const SubCont4 = styled.div`
  padding: 10px 0;
`;
const SubCont5 = styled.div`
  flex: .7;
`;
const SubCont6 = styled.div`
  flex: .3;
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color:#EBF1F5;
`;
const InfoDesplegableContainer = styled.section`
  border-bottom: 1px solid #EBF1F5  ;
  padding: 5px;
  .opened{
    transition: max-height 0.5s ease-in;
    max-height: auto;
  }
  .closed{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.20s ease-out;
  }
`;
const InfoDesplegableSubCont = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: row;
  align-items: center;
`;
const Collapsable = styled.div`
  padding: 5px 15px;
`;
const NumberIndicator = styled.div`
  background-color:rgba(143, 171, 195, 0.3);
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Table = styled.table`
  border: none;
`;
const Tableth = styled.th`
  font-size: 12px;
  color: #3D5176;
  font-family: 'Roboto medium';
  text-align: start;
  padding: 15px 5px;
`;
const Tabletd = styled.td`
  font-size: 12px;
  color: #3D5176;
  font-family: 'Roboto medium';
  text-align: start;
  width: 65%;
  padding: 15px 5px;
  @media (max-width: 700px){
    width: 90%;

  }
`;
const Tabletr = styled.tr`
  background-color: #fff9eb;
`;
const Separator = styled.div`
  padding: 0 16px 16px 0;
`;
