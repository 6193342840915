import React from 'react';
import styled from 'styled-components';
import { Text3, Text5M } from './texts';

export default function RetomaPrice({ text, price, position }: { text: string, price: string, position: string }) {
  return (
    <Container justifyContent={position}>
      <SubCont>
        <Text5M>
          $
          {price}
        </Text5M>
        <Text3>{text}</Text3>
      </SubCont>
    </Container>
  );
}
const Container = styled.div`
  width: 90%;
  display: flex;
  justify-content: ${({ justifyContent }: { justifyContent: string }) => justifyContent};
  margin-top: 20px;
`;
const SubCont = styled.div`
  padding: 10px;
  border-radius: 10px;
  background: #EBF1F5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
