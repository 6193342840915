function priceFormat(number:number) {
  const formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
  });
  return formatter.format(number).slice(1);
}

function roundPrice(valor:number, region:number) {
  let exp = 4;
  switch (region) {
    case 1: //  Chile
      exp = 4;
      break;
    case 2: // Colombia
      exp = 4;
      break;
    case 3: //  Mexico
      exp = 1;
      break;
    case 4: //  Peru
      exp = 1;
      break;
    default:
      break;
  }

  return Math.round(valor / (10 ** exp)) * (10 ** exp);
}

export { priceFormat, roundPrice };
