import React from 'react';
import styled from 'styled-components';

interface ComparativeBarProps {
  TriangleTop1: boolean,
  TriangleTop2: boolean,
  TriangleTop3: boolean,
  TriangleBottom1: boolean,
  TriangleBottom2: boolean,
  TriangleBottom3: boolean
}

export default function ComparativeBar(
  {
    TriangleTop1,
    TriangleTop2,
    TriangleTop3,
    TriangleBottom1,
    TriangleBottom2,
    TriangleBottom3,
  }
    : ComparativeBarProps,
) {
  return (
    <Container>
      <SubCont1>
        <ColorBar
          color="#627fb5"
          TriangleTop={TriangleTop1}
          TriangleBottom={TriangleBottom1}
          borderRadius="12px 0 0 12px"
        />
        <ColorBar
          color="#14346f"
          TriangleTop={TriangleTop2}
          TriangleBottom={TriangleBottom2}
          borderRadius="0"
        />
        <ColorBar
          color="#0e48b2"
          TriangleTop={TriangleTop3}
          TriangleBottom={TriangleBottom3}
          borderRadius="0 12px 12px 0"
        />
      </SubCont1>
    </Container>
  );
}

function ColorBar({
  color, TriangleTop, TriangleBottom, borderRadius,
}: { color: string, TriangleTop: boolean, TriangleBottom: boolean, borderRadius: string }) {
  return (
    <ColorBarCont>
      <IndicatorTriangleTop
        borderBottom={`6px solid ${TriangleTop ? color : 'transparent'}`}
      />
      <IndicatorBar backgroundColor={color} borderRadius={borderRadius} />
      <IndicatorTriangleBottom
        borderTop={`6px solid ${TriangleBottom ? color : 'transparent'}`}

      />
    </ColorBarCont>
  );
}

const Container = styled.div``;

const SubCont1 = styled.div`
  width: 90%;
  min-width: 250px;
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
`;

const ColorBarCont = styled.div`
  width: 33%;
`;
const IndicatorBar = styled.div<{backgroundColor: string, borderRadius: string}>`
  width: 100%;
  height: 7px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius};
`;
const IndicatorTriangleTop = styled.div`
  width: 0;
  height: 0;
  margin: auto;
  border-right: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: ${({ borderBottom }: { borderBottom: string }) => borderBottom}; 
`;

const IndicatorTriangleBottom = styled.div`
  width: 0;  
  height: 0;
  margin: auto;
  border-right: 6px solid transparent;
  border-top: ${({ borderTop }: { borderTop: string }) => borderTop}; 
  border-left: 6px solid transparent;
  border-bottom: 1px solid transparent;
`;
