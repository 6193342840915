import React from 'react';
import styled from 'styled-components';
import logoImg from '../assets/img/logo.png';

function Footer() {
  return (
    <ContainerFooter>
      <img height={55} src={logoImg} alt="autofact" />
    </ContainerFooter>
  );
}

export default Footer;

const ContainerFooter = styled.div`
  background-color: #0b3254;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
