import styled from 'styled-components';

export const Text1 = styled.label`
  font-size: 8px;
  color: #3D5176;
`;
export const Text1M = styled(Text1)`font-family: 'Roboto medium';`;
export const Text2 = styled.label`
  font-size: 10px;
  color: #3D5176;
`;
export const Text2M = styled(Text2)`font-family: 'Roboto medium';`;
export const Text3 = styled.label`
  font-size: 12px;
  color: #3D5176;
`;
export const Text3M = styled(Text3)`font-family: 'Roboto medium';`;
export const Text4 = styled.label`
  font-size: 14px;
  color: #3D5176;
`;
export const Text4M = styled(Text4)`font-family: 'Roboto medium';`;
export const Text5 = styled.label`
  font-size: 18px;
  color: #3D5176;
`;
export const Text5M = styled(Text5)`font-family: 'Roboto medium';`;
export const CardTitle = styled.label`
  font-size: 16px;
  color: #155892;
  font-family: 'Roboto medium';

  @media (max-width: 700px){
    font-size: 14px;
  }
`;
